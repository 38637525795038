import React, { useEffect, useState } from 'react';
import styles from './ProductSearch.module.css';
import AppIcon from '../../../core/ui/AppIcon/AppIcon';
import { translateLiteral } from '../../../core/literals/Literal';

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = SpeechRecognition ? new SpeechRecognition() : null;

if (recognition) {
  recognition.continous = false;
  recognition.interimResults = false;
  recognition.lang = 'es-ES';
}

const ProductSearch = ({ onChange, ...props }) => {
  const [writeText, setWriteText] = useState('');
  const [listening, setListening] = useState(false);
  const [speechText, setSpeechText] = useState('');

  useEffect(() => {
    setWriteText('');
    onChange(speechText);
  }, [speechText]);

  useEffect(() => {
    setSpeechText('');
    onChange(writeText);
  }, [writeText]);

  return (
    <div className={styles.container}>
      <div style={{ marginLeft: '8px' }}>
        <AppIcon color="#B1B1B1" size={20} icon="search" />
      </div>
      <input
        type="text"
        placeholder={translateLiteral('actions.search')}
        value={writeText ? writeText : speechText}
        className={styles.input}
        onChange={evt => setWriteText(evt.target.value)}
      />
      <div
        style={{ marginRight: '8px', display: 'flex', flexDirection: 'row' }}
        onClick={() => {
          toggleListen();
        }}
      >
        {recognition && listening && (
          <AppIcon color="#EF5959" size={18} icon={'pause-button'} />
        )}
        {recognition && (
          <AppIcon
            color={listening ? '#EF5959' : '#B1B1B1'}
            size={20}
            icon={'radio'}
          />
        )}
      </div>
    </div>
  );

  function initListening() {
    if (recognition) {
      recognition.onstart = () => {
        console.log('Listening!');
      };
      onResultListening();
    }
  }

  function onResultListening() {
    if (recognition) {
      let finalTranscript = '';
      recognition.onresult = event => {
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) finalTranscript += transcript + ' ';
        }

        setSpeechText(finalTranscript);

        const transcriptArr = finalTranscript.split(' ');
        const stopCmd = transcriptArr.slice(-3, -1);
        console.log('stopCmd', stopCmd);

        if (stopCmd[0] === 'stop' && stopCmd[1] === 'listening') {
          recognition.stop();
          recognition.onend = () => {
            console.log('Stopped listening per command');
            const finalText = transcriptArr.slice(0, -3).join(' ');
            setSpeechText(finalText);
          };
        }
      };
    }
  }
  function toggleListen() {
    initListening();

    if (recognition) {
      console.log('the app are listening?', listening);
      const listenState = !listening;
      setListening(listenState);

      if (listenState) {
        recognition.start();
        recognition.onend = () => {
          console.log('...continue listening...');
          recognition.start();
        };
      } else {
        recognition.stop();
        recognition.onend = () => {
          console.log('Stopped listening per click');
        };
      }

      recognition.onerror = event => {
        console.log('Error occurred in recognition: ' + event.error);
      };
    }
  }
};

export default ProductSearch;
