import React, { Fragment } from 'react';
import HomeInfo from './HomeInfo/HomeInfo';
import BannerTouchable from './banner/BannerTouchable';
import { putProductsItem } from '../../core/redux/actions/ProductsActions';
import { connect } from 'react-redux';

const Home = props => {
  return (
    <Fragment>
      <HomeInfo openProducts={props.openProducts} />
      <BannerTouchable openProducts={props.openProducts} />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    offert: state.products.offert,
    products: state.products.products.all,
  };
};

export default connect(mapStateToProps, {
  putProductsItem,
})(Home);
