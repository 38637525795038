import {
  fetchingLoginSuccess,
  fetchingRegisterSuccess,
  fetchingLoginFail,
  fetchingRegisterFail,
  fetchingPartnersSuccess,
  fetchingPartnersFail,
} from '../core/redux/actions/AuthActions';
import store from '../core/redux/store';
import history from '../core/router/history';
import {
  fetchGetPartners,
  fetchLoginUser,
  fetchRegisterUser,
  fetchRecoverUser,
} from './authRequests';

let user = null;

var AuthMode = {
  NOT_AUTHENTICATED: 1,
  AUTHENTICATED: 2,
  EXPIRED: 3,
};

/////////////// recover ///////////////
export function recoverUser(email) {
  return fetchRecoverUser(email);
}
/////////////// REGISTER ///////////////
export function getPartners(nif) {
  return fetchGetPartners(nif).then(result => {
    if (result) {
      store.dispatch(fetchingPartnersSuccess(result));
    } else {
      store.dispatch(fetchingPartnersFail());
    }
  });
}

export function registerUser(nif, id, email, password) {
  localStorage.setItem('nif', nif);

  return fetchRegisterUser(id, email, password).then(res => {
    if (res.status == 'error') {
      store.dispatch(fetchingRegisterFail());
    } else {
      store.dispatch(fetchingRegisterSuccess(email, password, res));
    }
  });
}

/////////////// LOGIN ///////////////
export function loginUser(username, password) {
  localStorage.removeItem('email');

  return fetchLoginUser(username, password)
    .then(user => {
      if (user.status == 'fail') {
        store.dispatch(fetchingLoginFail(user));
        return false;
      } else {
        store.dispatch(fetchingLoginSuccess(user));
        return true;
      }
    })
    .catch(error => {
      store.dispatch(fetchingLoginFail(username));
    });
}

/////////////// GETTERS ///////////////
const authenticationStatus = () =>
  isRegistered()
    ? isAuthenticated()
      ? AuthMode.AUTHENTICATED
      : AuthMode.EXPIRED
    : AuthMode.NOT_AUTHENTICATED;

function isRegistered() {
  return localStorage.getItem('partner_id');
}

function isAuthenticated() {
  return localStorage.getItem('email');
}

function getLocalStorageValue(key) {
  return localStorage.getItem(key);
}

function getToken() {
  return localStorage.getItem('token');
}

function invalidateSession() {
  localStorage.removeItem('token');
  history.push('/login');
}

/////////////// LOGOUT ///////////////
export function logoutUser() {
  return removeUserData().then(success => {
    if (localStorage.getItem('userData' === undefined)) {
    }
    if (user) {
      user.token = '';
    }
    history.push('/login');
    return true;
  });
}

export function removeUserData() {
  localStorage.removeItem('email');
  localStorage.removeItem('token');

  localStorage.removeItem('address_id');
  localStorage.removeItem('user_id');
  localStorage.removeItem('partner_id');
  localStorage.removeItem('nif');

  return Promise.resolve(true);
}

export {
  authenticationStatus,
  AuthMode,
  isRegistered,
  isAuthenticated,
  getToken,
  invalidateSession,
  getLocalStorageValue,
};
