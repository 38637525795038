import React, { Fragment } from 'react';
import styles from './ProductItemInfo.module.css';

const ProductItemAddress = ({ address, ...props }) => {
  return (
    <div className={styles.containerAddress}>
      <div className={styles.address}>
        {address && titleForAddress(address)}
      </div>
    </div>
  );
};

const titleForAddress = ({ name, street, street2, city }) => {
  return (
    <Fragment>
      {name && <p className="mb-0"> {name}</p>}
      {street && <p className="mb-0"> {street}</p>}
      {street2 && <p className="mb-0"> {street2}</p>}
      {city && <p className="mb-0"> {city}</p>}
    </Fragment>
  );
};

export default ProductItemAddress;
