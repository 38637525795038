import React, { useState } from 'react';
import { translateLiteral } from '../../../core/literals/Literal';
import Checkbox from '@material-ui/core/Checkbox';
import styles from './ConditionsCheckbox.module.css'; // Import css modules stylesheet as styles
import Modal from '../../../core/ui/Modal/Modal';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const ConditionsCheckbox = props => {
  const [status, setStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1898DB',
      },
    },
  });

  return (
    <div className={styles.container}>
      {showModal && (
        <Modal show={showModal} handleClose={setShowModal}>
          {translateLiteral('login.termsAndConditions')}
        </Modal>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
          width: '100%',
        }}
      >
        <MuiThemeProvider theme={theme}>
          <Checkbox
            checked={status}
            onChange={evt => {
              const status = evt.target.checked;
              setStatus(status);
              props.updateState(status);
            }}
            value="checkedA"
            color="primary"
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
          />
        </MuiThemeProvider>

        <div onClick={() => setShowModal(true)}>
          {translateLiteral('login.signin.acceptTerms')}
        </div>
      </div>
    </div>
  );
};

export default ConditionsCheckbox;
