import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ProductsCount from './ProductsCount/ProductsCount';
import ProductsCategoryList from './ProductsCategoryList/ProductsCategoryList';
import CategoriesSegmentedList from './SegmentedControlList/CategoriesSegmentedList';
import { fetchCart } from '../../core/redux/actions/OrderActions';
import { ToastContainer } from '../../core/utils/ToastUtils';
import * as safeAreaInsets from 'safe-area-insets';
import { putProductsItem } from '../../core/redux/actions/ProductsActions';
import { setComments } from '../../core/redux/actions/OrderActions';

const Products = ({
  searchValue,
  products,
  cart,
  openPack,
  fetchCart,
  orders,
  putProductsItem,
}) => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [bottomCounterHeight, setBottomCounterHeight] = useState(0);
  const [packCategory, setPackCategory] = useState();
  useEffect(() => {
    checkInProgressOrder({ orders, products, putProductsItem, setComments });
    fetchCart();
  }, [orders, products]);

  useEffect(() => {
    setBottomCounterHeight(cart && cart.length ? 83 : 0);
  }, [cart]);

  useEffect(() => {
    if (searchValue) {
      const filteredItems = products.filter(item =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredItems(filteredItems);
    } else {
      setFilteredItems(null);
    }
  }, [searchValue, products]);

  useEffect(() => {
    if (openPack) {
      const defaultPack = products.find(
        product => product.is_pack && product.is_default_pack
      );
      const categoryId = openPack && defaultPack ? defaultPack.categ_id : null;
      setPackCategory(categoryId);
    }
  }, [openPack, products]);

  return (
    <Fragment>
      <div
        style={{
          marginBottom:
            parseFloat(bottomCounterHeight + safeAreaInsets.bottom) + 'px',
        }}
      >
        {filteredItems ? (
          <ProductsCategoryList resultItems={filteredItems} />
        ) : (
          <CategoriesSegmentedList categoryIdToShow={packCategory} />
        )}
      </div>
      <div
        style={{
          '--product-count-height': bottomCounterHeight + 'px',
          paddingBottom: safeAreaInsets.bottom + 'px',
        }}
      >
        {cart && cart.length > 0 && <ProductsCount />}
      </div>{' '}
      <ToastContainer />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    products: state.products.products.all,
    cart: state.orders.cart,
    orders: state.orders.items,
  };
};

export default connect(mapStateToProps, {
  fetchCart,
  putProductsItem,
  setComments,
})(Products);

function checkInProgressOrder({
  orders = [],
  products = [],
  putProductsItem,
  setComments,
}) {
  const inProgressOrder = orders.find(order => order.state == 'draft');
  if (inProgressOrder && !localStorage.getItem('order_id')) {
    localStorage.setItem('order_id', inProgressOrder.id);
    setComments(inProgressOrder.comments);
    inProgressOrder.line_data.forEach(item => {
      const product = products.find(product => product.id == item.product_id);
      if (product) {
        putProductsItem({
          ...product,
          quantity: parseFloat(item.product_uom_qty),
        });
      }
    });
  }
}
