import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import AppText from '../../core/literals/AppText';
import { displayAppToolbarWithLogout } from '../../core/redux/actions/AppActions';
import {
  fetchChangePassword,
  fetchUserDetails,
} from '../../core/redux/actions/UserActions';
import history from '../../core/router/history';
import FormButton from '../../core/ui/FormButton/FormButton';
import FormInput from '../../core/ui/FormInput/FormInput';
import {
  notify,
  notifySuccess,
  ToastContainer,
} from '../../core/utils/ToastUtils';
import styles from './Profile.module.css';
import { translateLiteral } from '../../core/literals/Literal';
import { getDBTable } from '../../core/dbProvider';

const logoIconUrl = require('../../assets/logo_small.png');
const LogoIcon = () => (
  <div style={{ marginTop: '0px' }}>
    <img src={logoIconUrl} alt="" width="40" height="62" />
  </div>
);

const UserTitle = ({ name }) => <div className={styles.title}>{name}</div>;

const UserProfile = props => {
  const [oldPassword, setOldPassword] = useState('');
  const [invalidOldPassword, setInvalidOldPassword] = useState(true);

  const [newFirstPassword, setNewFirstPassword] = useState('');
  const [invalidNewFirstPassword, setInvalidNewFirstPassword] = useState(true);

  const [newSecondPassword, setNewSecondPassword] = useState('');
  const [invalidNewSecondPassword, setInvalidNewSecondPassword] = useState(
    true
  );

  const [address, setAddress] = useState();

  const { fetchUserDetails, displayAppToolbarWithLogout } = props;
  useEffect(() => {
    fetchUserDetails();
    displayAppToolbarWithLogout();
  }, [fetchUserDetails, displayAppToolbarWithLogout]);

  useEffect(() => {
    if (props.passwordChangedSuccessfully) {
      notifySuccess(translateLiteral('alerts.passwordChanged'));
      setTimeout(() => {
        history.push('/login');
      }, 1500);
    } else if (props.passwordChangedSuccessfully == false) {
      notify(translateLiteral('alerts.passwordNotChanged'));
    }
  }, [props.passwordChangedSuccessfully]);

  useEffect(() => {
    getDBTable('addresses').then(addresses => {
      if (addresses && addresses.length) {
        setAddress(addresses[0]);
      }
    });
  }, []);

  const changePassword = () => {
    if (invalidOldPassword) {
      notify(translateLiteral('validations.errors.invalidOldPassword'));
    } else if (invalidNewFirstPassword) {
      notify(translateLiteral('validations.errors.invalidNewPassword'));
    } else if (invalidNewSecondPassword) {
      notify(translateLiteral('validations.errors.invalidNewPassword'));
    } else if (newFirstPassword != newSecondPassword) {
      notify(translateLiteral('validations.errors.passwordsNotMatch'));
    } else {
      props.fetchChangePassword(oldPassword, newFirstPassword);
    }
  };

  let app_version = process.env.REACT_APP_VERSION;
  return (
    <Fragment>
      <div style={{ position: 'absolute', right: 8, top: 50, color: 'gray' }}>
        <AppText>v{app_version}</AppText>
      </div>
      <div className={[styles.container, 'd-flex', 'flex-column'].join(' ')}>
        <LogoIcon />

        <UserTitle name={props.name} />
        <div className={'mt-2'}>{address && address.street}</div>
        <div className={'mt-2'}>{address && address.city}</div>
        <div style={{ marginTop: '26px' }}>
          <FormInput
            type="username"
            name={translateLiteral('inputs.email')}
            initialValue={props.email}
          />
        </div>
        <div>
          <FormInput
            type="password"
            name={translateLiteral('inputs.oldPassword')}
            onChange={setOldPassword}
            invalidData={setInvalidOldPassword}
          />
        </div>
        <SeparatorBar />
        <div>
          <FormInput
            type="password"
            name={translateLiteral('inputs.newPassword')}
            onChange={setNewFirstPassword}
            invalidData={setInvalidNewFirstPassword}
          />
        </div>
        <div>
          <FormInput
            type="password"
            name={translateLiteral('inputs.repeatNewPassword')}
            onChange={setNewSecondPassword}
            invalidData={setInvalidNewSecondPassword}
          />
        </div>
        <div>
          <FormButton
            onClick={() => changePassword()}
            title={translateLiteral('actions.saveChanges')}
          />
        </div>
        <ToastContainer />
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    email: state.user.email,
    name: state.user.user.name,
    passwordChangedSuccessfully: state.user.passwordChangedSuccessfully,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUserDetails,
    fetchChangePassword,
    displayAppToolbarWithLogout: () => {
      return dispatch(displayAppToolbarWithLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

function SeparatorBar() {
  return <div className={styles.dashed} />;
}
