import React from 'react';

const AppText = ({ style, font, family, className, ...props }) => {
  let familytName = family ? family : 'Montserrat';
  let fontName = familytName;
  if (font) {
    fontName = fontName + '-' + font;
  }
  var newStyle = style ? [{ fontFamily: fontName }] : { fontFamily: fontName };
  if (style) {
    if (Array.isArray(style)) {
      newStyle = this.style.concat(style);
    } else if (style) {
      newStyle.push(style);
    }
  }

  return (
    <div {...props} className={className} style={newStyle}>
      {props.children}
    </div>
  );
};

export default AppText;
