import spanishMessages from './translations/es.json';
import { get } from 'lodash';
import 'moment/locale/es';

export const appLocale = () => 'es';

const literalsProvider = locale => {
  if (locale === 'es') {
    return spanishMessages;
  }
};

export const translateLiteral = (key, val) => {
  let value = get(literalsProvider(appLocale()), key);
  if (val) {
    value = value.replace('@', val);
  }
  return value;
};
