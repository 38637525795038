import { ToastContainer, toast } from 'react-toastify';

const notify = (message, position = toast.POSITION.BOTTOM_CENTER) =>
  toast.error(message, {
    position: position,
    hideProgressBar: true,
    autoClose: 2000,
  });

const notifySuccess = (message, position = toast.POSITION.BOTTOM_CENTER) =>
  toast.success(message, {
    position: position,
    hideProgressBar: true,
    autoClose: 2000,
  });

export { ToastContainer, notify, notifySuccess };
