import React from 'react';
import styles from './OrderButton.module.css';
import { openCartInvoice } from '../../router/NavigationUtils';

const OrderButton = ({ title, ...props }) => {
  return (
    <div
      className={styles.button}
      onClick={() => {
        openCartInvoice();
      }}
    >
      {title}
    </div>
  );
};

export default OrderButton;
