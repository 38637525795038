import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { translateLiteral } from '../../../core/literals/Literal';
import Confirm from '../../../core/ui/Modal/Confirm/Confirm';
import history from '../../../core/router/history';

export default function InvoiceCancelOrder({ visitsInfo }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  return (
    <>
      <Button
        onClick={() => setShowConfirmModal(true)}
        color="secondary"
        variant="contained"
        disabled={visitsInfo && !visitsInfo.canCancel}
      >
        {translateLiteral('actions.cancelOrder')}
      </Button>
      <Confirm
        title={
          <>
            <div>{translateLiteral('alerts.cancelOrder')}</div>
            <div>{translateLiteral('alerts.cancelOrderConfirm')}</div>
          </>
        }
        cancelButtonText={translateLiteral('actions.cancel')}
        okButtonText={translateLiteral('actions.confirmCancelation')}
        open={showConfirmModal}
        handleCancel={() => setShowConfirmModal(false)}
        handleOK={() => {
          setShowConfirmModal(false);
          history.push('/cancelOrder/' + localStorage.getItem('order_id'));
        }}
      ></Confirm>
    </>
  );
}
