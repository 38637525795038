import React from 'react';
import styles from './Modal.module.css';

const Modal = ({ handleClose, show, children }) => {
  return (
    <div className={styles.container}>
      <section className="modal-main">
        <div onClick={() => handleClose(false)} className={styles.close} />
        <div className={styles.content}>{children}</div>
      </section>
    </div>
  );
};

export default Modal;
