import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import styles from './Confirm.module.css';

export default function Confirm({
  open,
  handleOK,
  handleCancel,
  title,
  cancelButtonText,
  okButtonText,
}) {
  return (
    <Dialog open={open} onClose={handleCancel}>
      <div className={styles.title}>{title}</div>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="contained">
          {cancelButtonText}
        </Button>
        <Button onClick={handleOK} color="secondary" variant="contained">
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
