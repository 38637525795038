import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ProductItem from '../ProductItem/ProductItem';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ProductsCategoryList = ({ items, cart, resultItems, ...props }) => {
  const classes = useStyles();
  const products = resultItems ? resultItems : items;
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
      style={{ paddingBottom: 0, paddingTop: '0px' }}
    >
      {products &&
        products.length > 0 &&
        products.map((item, index) => (
          <ProductItem
            key={index}
            data={{ ...item }}
            isInCart={itemIsInCart(item.id, cart)}
          />
        ))}
    </List>
  );
};

const mapStateToProps = state => {
  return {
    items: state.products.products.currentCategory,
    cart: state.orders.cart,
  };
};
const itemIsInCart = (id, cart) => {
  const filteredProducts = cart ? cart.filter(product => product.id == id) : [];

  return filteredProducts.length > 0;
};

export default connect(mapStateToProps)(ProductsCategoryList);
