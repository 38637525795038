import React from 'react';
import styles from './OrderListCellInfo.module.css';
import ProductItemInfo from '../ProductItem/ProductItemInfo';
import ProductItemAddress from '../ProductItem/ProductItemAddress';
import RepeatOrderButton from '../../../core/ui/Button/RepeatOrderButton';
import { translateLiteral } from '../../../core/literals/Literal';

const OrderListCellInfo = ({ order, address, canRepeat }) => {
  return (
    <div className={styles.container}>
      <div className={styles.orderProductTitle}>
        {translateLiteral('orders.products')}
      </div>
      {order.line_data.map((item, idx) => (
        <ProductItemInfo key={idx} item={item} />
      ))}
      <div className={styles.orderProductTitle}>
        {translateLiteral('orders.deliveryAddress')}
      </div>
      <ProductItemAddress address={address} />
      <div style={{ marginTop: '16px', marginBottom: '16px', width: '100%' }}>
        <RepeatOrderButton id={order.id} canRepeat={canRepeat} />
      </div>
    </div>
  );
};

export default OrderListCellInfo;
