import {
  FETCH_ERROR_SHOW,
  FETCH_ERROR_HIDE,
  PUT_PRODUCTS_ITEM,
  REMOVE_PRODUCTS_ITEM,
  REMOVE_ALL_PRODUCTS_ITEM,
  FETCHING_CART_SUCCESS,
  FETCH_CREATE_ORDER,
  FETCHING_CREATE_ORDER_SUCCESS,
  FETCHING_CREATE_ORDER_FAIL,
  FETCH_ORDERS,
  FETCHING_ORDERS_SUCCESS,
  FETCHING_ORDERS_FAIL,
  FETCHING_CANCEL_ORDER_SUCCESS,
  FETCHING_CANCEL_ORDER_FAIL,
  SET_COMMENTS,
} from '../actions/types';
import { putItems, deleteItem, deleteItems } from '../../dbProvider';
import moment from 'moment';

export default (
  state = {
    list: undefined,
    items: undefined,
    current: undefined,
    cart: null,
    visits: undefined,
    lastDeal: undefined,
    loadingData: false,
    comments: null,
  },
  action
) => {
  switch (action.type) {
    case FETCH_ERROR_HIDE:
      return {
        ...state,
        showToast: false,
        errorMessage: null,
      };

    case FETCH_ERROR_SHOW:
      return {
        ...state,
        showToast: true,
        errorMessage: action.payload.errorMessage,
      };

    case FETCH_CREATE_ORDER:
      return {
        ...state,
        showToast: false,
        loadingData: true,
      };

    case FETCHING_CREATE_ORDER_SUCCESS:
      localStorage.removeItem('order_id');
      deleteItems('cart');
      return {
        ...state,
        showToast: false,
        loadingData: false,
        cart: [],
      };
    case FETCHING_CREATE_ORDER_FAIL:
      return {
        ...state,
        showToast: false,
        loadingData: false,
      };

    case PUT_PRODUCTS_ITEM:
      const cartPut = insertOrUpdateItemInShoppingCart(
        state.cart || [],
        action.action
      );
      putItems('cart', cartPut);
      return {
        ...state,
        cart: [...cartPut],
      };

    case REMOVE_PRODUCTS_ITEM:
      const cartRemove = removeItemInShoppingCart(state.cart, action.action);

      deleteItem('cart', action.action.id);

      return {
        ...state,
        cart: [...cartRemove],
      };

    case REMOVE_ALL_PRODUCTS_ITEM:
      deleteItems('cart');

      return {
        ...state,
        cart: [],
      };

    case FETCHING_CART_SUCCESS:
      return {
        ...state,
        cart: action.action,
      };
    case FETCH_ORDERS:
      return {
        items: [],
        loadingData: true,
      };
    case FETCHING_ORDERS_SUCCESS:
      const items = action.action.sort((firstItem, secondItem) => {
        return moment(firstItem.date_order, 'MM/DD/YYYY, HH:mm:ss') >=
          moment(secondItem.date_order, 'MM/DD/YYYY, HH:mm:ss')
          ? -1
          : 1;
      });
      putItems('orders', items);
      deleteItems('cart');
      localStorage.removeItem('order_id');
      return {
        comments: null,
        items,
        loadingData: false,
      };

    case FETCHING_ORDERS_FAIL:
      return {
        loadingData: false,
      };

    case FETCHING_CANCEL_ORDER_SUCCESS:
      localStorage.removeItem('order_id');
      deleteItems('cart');
      return {
        ...state,
        loadingData: false,
        cart: [],
      };
    case FETCHING_CANCEL_ORDER_FAIL:
      return {
        ...state,
        loadingData: false,
      };

    case SET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    default:
      return state;
  }
};

const insertOrUpdateItemInShoppingCart = (items, newItem) => {
  var oldItem = items && items.length && items.find(i => i.id === newItem.id);

  if (oldItem) {
    const index = items.indexOf(oldItem);

    items[index].quantity = newItem.quantity;
  } else {
    items.push(newItem);
  }
  return JSON.parse(JSON.stringify(items));
};

const removeItemInShoppingCart = (items, newItem) => {
  return items.filter(item => item.id !== newItem.id);
};
