//////////////////////////// CORE ////////////////////////
export const LOADING_DATA_START = 'LOADING_DATA_START';
export const LOADING_DATA_END = 'LOADING_DATA_END';

export const APP_TOOLBAR_SHOW = 'APP_TOOLBAR_SHOW';
export const APP_TOOLBAR_HIDE = 'APP_TOOLBAR_HIDE';
export const APP_TOOLBAR_SHOW_WITH_BACK = 'APP_TOOLBAR_SHOW_WITH_BACK';
export const APP_TOOLBAR_SHOW_RECOVER_WITH_BACK =
  'APP_TOOLBAR_SHOW_RECOVER_WITH_BACK';
export const APP_TOOLBAR_SHOW_WITH_REGISTER = 'APP_TOOLBAR_SHOW_WITH_REGISTER';
export const APP_TOOLBAR_SHOW_WITH_LOGOUT = 'APP_TOOLBAR_SHOW_WITH_LOGOUT';
export const APP_TOOLBAR_SHOW_ORDER_WITH_BACK =
  'APP_TOOLBAR_SHOW_ORDER_WITH_BACK';

export const APP_TOOLBAR_SHOW_PROFILE_WITH_BACK =
  'APP_TOOLBAR_SHOW_PROFILE_WITH_BACK';

export const FETCH_LITERALS = 'FETCH_LITERALS';
export const FETCHING_LITERALS_SUCCESS = 'FETCHING_LITERALS_SUCCESS';
export const FETCHING_LITERALS_FAIL = 'FETCHING_LITERALS_FAIL';

export const FETCH_APP_CONFIG = 'FETCH_APP_CONFIG';
export const FETCHING_APP_CONFIG_SUCCESS = 'FETCHING_APP_CONFIG_SUCCESS';
export const FETCHING_APP_CONFIG_FAIL = 'FETCHING_APP_CONFIG_FAIL';

//////////////////////////// AUTH ////////////////////////
export const FETCH_LOGIN = 'FETCH_LOGIN';
export const FETCHING_LOGIN_SUCCESS = 'FETCHING_LOGIN_SUCCESS';
export const FETCHING_LOGIN_FAIL = 'FETCHING_LOGIN_FAIL';

export const FETCH_AUTH_STATE = 'FETCH_AUTH_STATE';
export const FETCHING_AUTH_STATE_NOT_AUTHENTICATED =
  'FETCHING_AUTH_STATE_NOT_AUTHENTICATED';
export const FETCHING_AUTH_STATE_AUTHENTICATED =
  'FETCHING_AUTH_STATE_AUTHENTICATED';
export const FETCHING_AUTH_STATE_EXPIRED = 'FETCHING_AUTH_STATE_EXPIRED';

export const FETCH_REGISTER = 'FETCH_REGISTER';
export const FETCHING_REGISTER_SUCCESS = 'FETCHING_REGISTER_SUCCESS';
export const FETCHING_REGISTER_FAIL = 'FETCHING_REGISTER_FAIL';

export const FETCH_AUTHORIZE = 'FETCH_AUTHORIZE';
export const FETCHING_AUTHORIZE_SUCCESS = 'FETCHING_AUTHORIZE_SUCCESS';
export const FETCHING_AUTHORIZE_FAIL = 'FETCHING_AUTHORIZE_FAIL';

export const FETCH_PARTNERS = 'FETCH_PARTNERS';
export const FETCHING_PARTNERS_SUCCESS = 'FETCHING_PARTNERS_SUCCESS';
export const FETCHING_PARTNERS_FAIL = 'FETCHING_PARTNERS_FAIL';

export const RESET_REGISTER_FORM = 'RESET_REGISTER_FORM';

//////////////////////////// USER ////////////////////////
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const FETCHING_USER_DETAILS_SUCCESS = 'FETCHING_USER_DETAILS_SUCCESS';
export const FETCHING_USER_DETAILS_FAIL = 'FETCHING_USER_DETAILS_FAIL';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCHING_ORDERS_SUCCESS = 'FETCHING_ORDERS_SUCCESS';
export const FETCHING_ORDERS_FAIL = 'FETCHING_ORDERS_FAIL';

export const FETCH_USER_ADDRESSES = 'FETCH_USER_ADDRESSES';
export const FETCHING_USER_ADDRESSES_SUCCESS =
  'FETCHING_USER_ADDRESSES_SUCCESS';
export const FETCHING_USER_ADDRESSES_FAIL = 'FETCHING_USER_ADDRESSES_FAIL';

export const FETCH_USER_VISITS = 'FETCH_USER_VISITS';
export const FETCHING_USER_VISITS_SUCCESS = 'FETCHING_USER_VISITS_SUCCESS';
export const FETCHING_USER_VISITS_FAIL = 'FETCHING_USER_VISITS_FAIL';

export const FETCH_USER_CHANGE_PASSWORD = 'FETCH_USER_CHANGE_PASSWORD';
export const FETCHING_USER_CHANGE_PASSWORD_SUCCESS =
  'FETCHING_USER_CHANGE_PASSWORD_SUCCESS';
export const FETCHING_USER_CHANGE_PASSWORD_FAIL =
  'FETCHING_USER_CHANGE_PASSWORD_FAIL';

export const FETCH_USER_RESET_PASSWORD = 'FETCH_USER_RESET_PASSWORD';
export const FETCHING_USER_RESET_PASSWORD_SUCCESS =
  'FETCHING_USER_RESET_PASSWORD_SUCCESS';
export const FETCHING_USER_RESET_PASSWORD_FAIL =
  'FETCHING_USER_RESET_PASSWORD_FAIL';

//////////////////////////// PRODUCTS ////////////////////////
export const FETCHING_CART_SUCCESS = 'FETCHING_CART_SUCCESS';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCHING_CATEGORIES_SUCCESS = 'FETCHING_CATEGORIES_SUCCESS';
export const FETCHING_CATEGORIES_FAIL = 'FETCHING_CATEGORIES_FAIL';

export const SELECT_CATEGORY = 'SELECT_CATEGORY';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCHING_PRODUCTS_SUCCESS = 'FETCHING_PRODUCTS_SUCCESS';
export const FETCHING_PRODUCTS_FAIL = 'FETCHING_PRODUCTS_FAIL';
export const FETCH_OFFERS = 'FETCH_OFFERS';
export const FETCHING_OFFERS_SUCCESS = 'FETCHING_OFFERS_SUCCESS';
export const FETCHING_OFFERS_FAIL = 'FETCHING_OFFERS_FAIL';

export const PUT_PRODUCTS_ITEM = 'PUT_PRODUCTS_ITEM';
export const REMOVE_PRODUCTS_ITEM = 'REMOVE_PRODUCTS_ITEM';
export const REMOVE_ALL_PRODUCTS_ITEM = 'REMOVE_ALL_PRODUCTS_ITEM';

export const FETCH_ERROR_SHOW = 'FETCH_ERROR_SHOW';
export const FETCH_ERROR_HIDE = 'FETCH_ERROR_HIDE';

/////ORDERS////
export const FETCH_CART = 'FETCH_CART';
export const FETCH_CREATE_ORDER = 'FETCH_CREATE_ORDER';
export const FETCHING_CREATE_ORDER_SUCCESS = 'FETCHING_CREATE_ORDER_SUCCESS';
export const FETCHING_CREATE_ORDER_FAIL = 'FETCHING_CREATE_ORDER_FAIL';
export const FETCHING_CANCEL_ORDER_SUCCESS = 'FETCHING_CANCEL_ORDER_SUCCESS';
export const FETCHING_CANCEL_ORDER_FAIL = 'FETCHING_CANCEL_ORDER_FAIL';
export const SET_COMMENTS = 'SET_COMMENTS';
