import React from 'react';
import styles from './RecentActivity.module.css';
import { translateLiteral } from '../../../core/literals/Literal';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const dayOfMonth = date => moment(date, dateFormat).format('D');

function monthText(date) {
  return moment(date, dateFormat)
    .format('MMM')
    .toString()
    .toUpperCase();
}

const now = moment();
function nextRouteText(weekday) {
  return (
    weekday &&
    `${translateLiteral('home.routeInfo')}${now
      .day(weekday)
      .format('dddd')
      .toUpperCase()} *`
  );
}

const RecentActivity = ({ visitsInfo = {}, addresses }) => {
  let { next_date: nextDate, weekday } = visitsInfo;
  return (
    <>
      <div className={styles.container}>
        <div className={styles.activityContainer}>
          <div className={`${styles.activityTitle} ${styles.nextRoute}`}>
            {nextRouteText(weekday)}
          </div>
        </div>
      </div>
      {nextDate && (
        <div className={styles.container}>
          <div className={styles.dateContainer}>
            <div className={styles.dateTitle}>{dayOfMonth(nextDate)}</div>
            <div className={styles.dateSubtitle}>{monthText(nextDate)}</div>
          </div>
          <div className={styles.activityContainer}>
            <div className={styles.activityTitle}>
              {translateLiteral('home.upcomingDeal')}
            </div>
            <div className={styles.activitySubtitle}>
              {addresses && addresses.length && getAddressText(addresses[0])}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function getAddressText({ street, street2, city }) {
  return `${street || ''} ${street2 || ''} ${city || ''}`;
}

export default RecentActivity;
