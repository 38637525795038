import React from 'react';
import NewOrderButton from '../../../core/ui/Button/NewOrderButton';
import RecentActivity from '../RecentActivity/RecentActivity';
import styles from '../home.module.css';
import { connect } from 'react-redux';
import EditOrderButton from '../../../core/ui/Button/EditOrderButton';
import { translateLiteral } from '../../../core/literals/Literal';

const HomeInfo = ({ visitsInfo, orders = [], addresses, user }) => {
  const inProgressOrder = orders.find(order => order.state == 'draft');
  return (
    <div className={styles.homeInfoContainer}>
      <div className={styles.homeInfoWrapperContent}>
        <RecentActivity visitsInfo={visitsInfo} addresses={addresses} />
        {inProgressOrder ? (
          <EditOrderButton
            inProgressOrder={inProgressOrder}
            visitsInfo={visitsInfo}
            blocked={Boolean(user.blocked)}
          />
        ) : (
          <NewOrderButton blocked={Boolean(user.blocked)} />
        )}
        <div className={styles.note}>{translateLiteral('home.note')}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    visitsInfo: state.user.visitsInfo,
    orders: state.orders.items,
    products: state.products.products.all,
    addresses: state.user.addresses,
    user: state.user.user,
  };
};

export default connect(mapStateToProps)(HomeInfo);
