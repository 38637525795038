import React from 'react';
import styles from './Button.module.css';
import { translateLiteral } from '../../literals/Literal';
import history from '../../router/history';

const RepeatOrderButton = ({ id, canRepeat }) => {
  return (
    <>
      <div
        className={`${styles.button} ${canRepeat ? '' : styles.disabled}`}
        onClick={() => {
          if (canRepeat) {
            history.push(`/processOrder/${id}`);
          }
        }}
      >
        {translateLiteral('actions.repeatPack').toUpperCase()}
      </div>
      {!canRepeat ? (
        <div className={styles.cantEdit}>
          {translateLiteral('orders.cantRepeat')}
        </div>
      ) : null}
    </>
  );
};

export default RepeatOrderButton;
