import React from 'react';
import { connect } from 'react-redux';
import styles from './ProductItem.module.css';
import QuantityCounter from '../QuantityCounter/QuantityCounter';
import {
  putProductsItem,
  removeProductsItem,
} from '../../../core/redux/actions/ProductsActions';
import { translateLiteral } from '../../../core/literals/Literal';

const handleProductToShoppingCart = (props, item, quantity) => {
  if (quantity == 0) {
    props.removeProductsItem(item);
  } else {
    props.putProductsItem({ ...item, quantity: quantity });
  }
};

const ProductItem = ({ data, isInCart = false, products, ...props }) => {
  const {
    id,
    name,
    image_url,
    list_price,
    is_pack: isPack,
    pack_items: packItems,
  } = data;

  return (
    <div
      className={`${isInCart ? styles.containerActive : styles.container} ${
        isPack ? styles.pack : null
      }`}
    >
      {!isPack && (
        <div className={styles.iconContainer}>
          <img src={image_url} alt={'Product' + id} width="100%" />
        </div>
      )}
      <div className={styles.dataContainer}>
        <div className={styles.title}>{name} </div>
        {isPack && packDetail(packItems, products)}
        <div className={styles.bottomContainer}>
          <div className={styles.price}>{list_price} €</div>
          <div className={styles.quantity}>
            <QuantityCounter
              productId={id}
              valueChanged={value =>
                handleProductToShoppingCart(props, data, value)
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.horizontalLine} />
    </div>
  );
};

function packDetail(packItems, products = []) {
  return packItems && packItems.length ? (
    <div className={styles.packItems}>
      {packItems.map((packItem, key) => {
        const product = products.find(
          product => product.id === packItem.product_name
        );
        return (
          product && (
            <div className={styles.packItem} key={key}>
              <div className={styles.packIteTitle}>{product.name}</div>
              <div className={styles.packItemAmount}>
                {packItem.product_quantity} {translateLiteral('products.units')}
              </div>
            </div>
          )
        );
      })}
    </div>
  ) : null;
}

const mapStateToProps = state => {
  return {
    offert: state.products.offert,
    products: state.products.products.all,
    lastDeal: state.orders.lastDeal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeProductsItem: item => {
      return dispatch(removeProductsItem(item));
    },
    putProductsItem: item => {
      return dispatch(putProductsItem(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);
