import React from 'react';
import styles from './LabelLink.module.css';
import history from '../../router/history';

const LabelLink = ({ title, routeName, link, ...props }) => {
  return (
    <div
      className={styles.label}
      onClick={() => {
        history.push("/"+routeName);
      }}
    >
      {title}
    </div>
  );
};

export default LabelLink;
