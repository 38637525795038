import React, { useState, useEffect } from 'react';
import styles from './ProcessOrder.module.css';
import history from '../../core/router/history';
import { connect } from 'react-redux';
import { hideAppToolbar } from '../../core/redux/actions/AppActions';
import {
  processOrder,
  fetchUserOrders,
} from '../../core/redux/actions/OrderActions';
import { get } from 'lodash';
import store from '../../core/redux/store';
import { translateLiteral } from '../../core/literals/Literal';
import Loader from 'react-loader-spinner';

const ProcessOrder = ({ hideAppToolbar, ...props }) => {
  hideAppToolbar();
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    setProcessing(true);

    const orderId = get(props, 'match.params.id');
    if (orderId) {
      doOrder()
        .then(success => {
          setProcessing(false);
          if (!success) {
            history.push(`/OrderFail/${orderId}`);
          }
          props.fetchUserOrders();
        })
        .catch(err => {
          setProcessing(false);
          history.push('/OrderFail');
        });
    }
  }, []);

  const goBack = () => {
    history.push('/home?tab=0');
  };

  const makeOrder = data => processOrder(data);

  function doOrder() {
    const orderId = get(props, 'match.params.id');
    if (orderId && orderId == 0) {
      return makeOrder();
    } else if (orderId) {
      const items = store.getState().orders.items;
      if (items) {
        const result = items.find(item => item.id == orderId);
        if (result) {
          return makeOrder(result.line_data);
        } else {
          return Promise.reject();
        }
      } else {
        return Promise.reject();
      }
    } else {
      return Promise.reject();
    }
  }

  return (
    <div className={styles.container}>
      {processing && (
        <Loader type="TailSpin" color="#61A0ED" height="90" width="90" />
      )}

      <div className={styles.title}>
        {' '}
        {processing
          ? translateLiteral('orders.processingOrder')
          : translateLiteral('orders.orderProcessed')}
      </div>
      {!processing && (
        <div
          className={styles.finishButton}
          onClick={() => {
            goBack();
          }}
        >
          {translateLiteral('actions.back').toUpperCase()}
        </div>
      )}
    </div>
  );
};

export default connect(
  null,
  {
    hideAppToolbar,
    processOrder,
    fetchUserOrders,
  }
)(ProcessOrder);
