import {
  FETCH_AUTH_STATE,
  FETCHING_AUTH_STATE_NOT_AUTHENTICATED,
  FETCHING_AUTH_STATE_AUTHENTICATED,
  FETCHING_AUTH_STATE_EXPIRED,
  FETCHING_LOGIN_FAIL,
  FETCH_LOGIN,
  FETCHING_LOGIN_SUCCESS,
  FETCHING_REGISTER_FAIL,
  FETCH_REGISTER,
  FETCHING_PARTNERS_SUCCESS,
  FETCH_PARTNERS,
  FETCHING_PARTNERS_FAIL,
  RESET_REGISTER_FORM,
} from '../actions/types';
import { AuthMode, authenticationStatus } from '../../../api/authManager';

export default (
  state = {
    user: undefined,
    email: null,
    token: null,
    auth_status: authenticationStatus(),
    loginFail: false,
    registerFail: false,
    loadingData: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_AUTH_STATE:
      return {
        ...state,
        auth_status: null,
      };

    case 'RESET_ERROR_MESSAGE':
      return {
        ...state,
        loginFail: false,
        loadingData: false,
        registerFail: false,
        errorMessage: null,
      };

    case FETCH_REGISTER:
      return {
        ...state,
        loginFail: false,
        loadingData: true,
        errorMessage: null,
      };

    case FETCH_LOGIN:
      return {
        ...state,
        loginFail: false,
        loadingData: true,
      };
    case FETCHING_LOGIN_SUCCESS:
      return {
        ...state,
        loadingData: false,
      };

    case FETCHING_LOGIN_FAIL:
      return {
        ...state,
        errorMessage: 'Invalid credentials',
        loginFail: true,
        loadingData: false,
      };

    case FETCHING_REGISTER_FAIL:
      return {
        ...state,
        errorMessage: 'Register error',
        registerFail: true,
        loadingData: false,
      };

    case FETCHING_AUTH_STATE_NOT_AUTHENTICATED:
      return {
        ...state,
        auth_status: AuthMode.NOT_AUTHENTICATED,
      };

    case FETCHING_AUTH_STATE_AUTHENTICATED:
      return {
        ...state,
        auth_status: AuthMode.AUTHENTICATED,
      };

    case FETCHING_AUTH_STATE_EXPIRED:
      return {
        ...state,
        auth_status: AuthMode.EXPIRED,
      };

    case FETCH_PARTNERS:
      return {
        ...state,
        loginFail: false,
        loadingData: true,
        errorMessage: null,
      };

    case FETCHING_PARTNERS_SUCCESS:
      return {
        ...state,
        loadingData: false,
        partners: action.data,
      };

    case FETCHING_PARTNERS_FAIL:
      return {
        ...state,
        loadingData: false,
      };

    case RESET_REGISTER_FORM:
      return {
        partners: null,
        registerFail: false,
        loadingData: false,
      };

    default:
      return state;
  }
};
