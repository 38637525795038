import React from 'react';
import styles from './LoadingView.module.css';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';

const LoadingView = ({ id, title, onSelect, selected, loading, ...props }) => {
  return (
    loading && (
      <div className={styles.container}>
        <Loader type="TailSpin" color="#61A0ED" height={100} width={100} />
      </div>
    )
  );
};

const mapStateToProps = state => {
  const loading =
    state.user.loadingData ||
    state.auth.loadingData ||
    state.orders.loadingData ||
    state.products.loadingData;
  return {
    loading,
  };
};

export default connect(mapStateToProps)(LoadingView);
