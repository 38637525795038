import React, { useState, useEffect } from 'react';
import styles from './login.module.css'; // Import css modules stylesheet as styles
import FormInput from '../../core/ui/FormInput/FormInput';
import FormButton from '../../core/ui/FormButton/FormButton';
import LabelLink from '../../core/ui/LabelLink/LabelLink';
import LogoImage from '../../assets/logo_big.png';
import { connect } from 'react-redux';
import { notify, ToastContainer } from '../../core/utils/ToastUtils';
import {
  fetchLogin,
  resetErrorMessage,
} from '../../core/redux/actions/AuthActions';
import history from '../../core/router/history';
import { hideAppToolbar } from '../../core/redux/actions/AppActions';
import InstallOnIos from '../../core/ui/InstallOnIos';
import { translateLiteral } from '../../core/literals/Literal';
import AppText from '../../core/literals/AppText';

const SignIn = ({
  loginFail,
  registerFail,
  errorMessage,
  hideAppToolbar,
  fetchLogin,
  resetErrorMessage,
}) => {
  const [username, setUsername] = useState(null);
  const [invalidUsername, setInvalidUsername] = useState(true);
  const [password, setPassword] = useState(null);
  const [invalidPassword, setInvalidPassword] = useState(true);
  hideAppToolbar();

  if (loginFail && errorMessage) {
    resetErrorMessage('login');
    notify(errorMessage);
  }

  if (registerFail && errorMessage) {
    // resetErrorMessage("login")
    notify(errorMessage);
  }
  const doLogin = () => {
    window.blur();

    if (invalidUsername) {
      notify(translateLiteral('validations.errors.invalidEmail'));
    } else if (invalidPassword) {
      notify(translateLiteral('validations.errors.invalidPassword'));
    } else {
      fetchLogin(username, password);
    }
  };

  const goRegister = () => {
    history.push('/register');
  };

  localStorage.removeItem('token');

  useEffect(() => {
    localStorage.removeItem('order_id');
  }, []);

  return (
    <div className="d-flex flex-column">
      <InstallOnIos
        style={{
          position: 'absolute',
        }}
      />
      <div>
        <img alt="logo" className={styles.logo} src={LogoImage} />
      </div>
      <FormInput
        type="username"
        name={translateLiteral('inputs.email')}
        onChange={setUsername}
        invalidData={setInvalidUsername}
      />
      <FormInput
        type="password"
        name={translateLiteral('inputs.password')}
        onChange={setPassword}
        invalidData={setInvalidPassword}
      />
      <FormButton onClick={() => doLogin()} />
      <FormButton styleMode="register" onClick={() => goRegister()} />
      <LabelLink
        title={translateLiteral('login.signin.recoverTitle')}
        routeName="recover"
      />
      <ToastContainer />
      <div className={styles.bottomContainer}>
        <AppText className={styles.version}>
          v{process.env.REACT_APP_VERSION}
        </AppText>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loginFail: state.auth.loginFail,
    registerFail: state.auth.registerFail,
    errorMessage: state.auth.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideAppToolbar: () => {
      return dispatch(hideAppToolbar());
    },
    fetchLogin: (username, password) => {
      return dispatch(fetchLogin(username, password));
    },
    resetErrorMessage: type => {
      return dispatch(resetErrorMessage(type));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
