import React from 'react';
import styles from './SegmentedControl.module.css';

const SegmentedControlItem = ({ id, title, selected, onSelect, ...props }) => {
  return (
    <div
      className={selected ? styles.activeItem : styles.inactiveItem}
      onClick={() => {
        onSelect(id);
      }}
    >
      {title}
    </div>
  );
};

export default SegmentedControlItem;
