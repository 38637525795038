import {
  LOADING_DATA_START,
  LOADING_DATA_END,
  APP_TOOLBAR_HIDE,
  APP_TOOLBAR_SHOW,
  APP_TOOLBAR_SHOW_WITH_BACK,
  APP_TOOLBAR_SHOW_WITH_LOGOUT,
  APP_TOOLBAR_SHOW_WITH_REGISTER,APP_TOOLBAR_SHOW_RECOVER_WITH_BACK,APP_TOOLBAR_SHOW_ORDER_WITH_BACK,APP_TOOLBAR_SHOW_PROFILE_WITH_BACK
} from './types';

export const displayLoadingView = () => {
  return {
    type: LOADING_DATA_START,
  };
};

export const hideLoadingView = () => {
  return {
    type: LOADING_DATA_END,
  };
};

export const displayAppToolbar = () => {
  return {
    type: APP_TOOLBAR_SHOW,
  };
};




export const hideAppToolbar = () => {
  return {
    type: APP_TOOLBAR_HIDE,
  };
};



export const displayAppToolbarWithBack = () => {
  return {
    type: APP_TOOLBAR_SHOW_WITH_BACK,
  };
};

export const displayRecoverAppToolbar = () => {
  return {
    type: APP_TOOLBAR_SHOW_RECOVER_WITH_BACK,
  };
};

export const displayProfileAppToolbar = () => {
  return {
    type: APP_TOOLBAR_SHOW_PROFILE_WITH_BACK,
  };
};


export const displayOrderAppToolbar = () => {
  return {
    type: APP_TOOLBAR_SHOW_ORDER_WITH_BACK,
  };
};




export const displayAppToolbarWithLogout= () => {
  return {
    type: APP_TOOLBAR_SHOW_WITH_LOGOUT,
  };
};


export const displayRegisterAppToolbar = () => {
  return {
    type: APP_TOOLBAR_SHOW_WITH_REGISTER,
  };
};