import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppIcon from '../AppIcon/AppIcon';
import history from '../../router/history';
import {
  openCartInvoice,
  openProfile,
  openLogout,
} from '../../router/NavigationUtils';
import { translateLiteral } from '../../literals/Literal';
import navLogo from '../../../assets/logo_top.png';
import Badge from '@material-ui/core/Badge';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AppText from '../../literals/AppText';
import { removeAllProductsItems } from '../../redux/actions/ProductsActions';
import Confirm from '../Modal/Confirm/Confirm';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1898DB',
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '70px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    justifySelf: 'start',
    color: '#4B5B63',
    marginTop: '4px',
    fontSize: '15px',
  },
  iconProfileContainer: {
    marginLeft: '-10px',
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '70px',
    height: '56px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#00abc8',
    fontSize: '10px',
    textAlign: 'left',
  },
  iconContainer: {
    marginLeft: '8px',
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '70px',
    height: '56px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#00abc8',
    fontSize: '10px',
    textAlign: 'left',
  },
  iconRightContainer: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    width: '50px',
    height: '56px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AppNavBar = ({
  showBackButton,
  showLogoutButton,
  showRegisterMode,
  showRecoverMode,
  showOrderMode,
  showProfileMode,
  cart,
  removeAllProductsItems,
}) => {
  const [showRemoveProductsModal, setShowRemoveProductsModal] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const currentOrder = localStorage.getItem('order_id');
  useEffect(() => {
    const count = cart
      ? cart.map(item => item.quantity).reduce((a, b) => a + b, 0)
      : 0;
    setCartCount(count);
  }, [cart]);

  const classes = useStyles();
  const leftButton = showBackButton ? (
    <div
      className={classes.iconContainer}
      onClick={() => {
        history.goBack();
      }}
    >
      <AppIcon color="#00abc8" size={23} icon="back" />
      <AppText font="Bold">{translateLiteral('actions.back')}</AppText>
    </div>
  ) : (
    <div
      className={classes.iconProfileContainer}
      onClick={() => {
        openProfile();
      }}
    >
      <AppIcon color="#B1B1B1" size={25} icon="user-1" />
    </div>
  );

  const rightButton = !showBackButton ? (
    <div
      className={classes.iconRightContainer}
      onClick={() => {
        openCartInvoice();
      }}
    >
      {cartCount > 0 || currentOrder ? (
        <>
          <AppIcon color="#B1B1B1" size={25} icon="shopping-cart" />
          <MuiThemeProvider theme={theme}>
            <Badge
              style={{ marginTop: '-16px' }}
              badgeContent={cartCount}
              color="primary"
            />
          </MuiThemeProvider>
        </>
      ) : null}
    </div>
  ) : showLogoutButton ? (
    <div
      className={classes.iconRightContainer}
      onClick={() => {
        openLogout();
      }}
    >
      <AppIcon color="#FD6772" size={25} icon="logout" />
    </div>
  ) : showOrderMode ? (
    cartCount > 0 && (
      <div
        className={classes.iconRightContainer}
        onClick={() => {
          setShowRemoveProductsModal(true);
        }}
      >
        <AppIcon color="#FD6772" size={25} icon="delete" />
      </div>
    )
  ) : null;

  return (
    <div>
      <div
        className={classes.root}
        style={showBackButton ? { height: '52px' } : null}
      >
        <div>{leftButton}</div>
        <div className={classes.title}>
          {showRegisterMode ? (
            <AppText font="Bold">
              {translateLiteral('actions.register').toUpperCase()}
            </AppText>
          ) : showRecoverMode ? (
            <AppText font="Bold">
              {translateLiteral('actions.recover').toUpperCase()}
            </AppText>
          ) : showOrderMode ? (
            <AppText font="Bold">
              {translateLiteral('orders.myOrder').toUpperCase()}
            </AppText>
          ) : showProfileMode ? (
            <AppText font="Bold">
              {translateLiteral('menu.profile').toUpperCase()}
            </AppText>
          ) : (
            <img src={navLogo} alt="navLogo" height="44px" />
          )}
        </div>
        {rightButton}
      </div>
      {addConfirmDialog(
        showRemoveProductsModal,
        setShowRemoveProductsModal,
        removeAllProductsItems
      )}
    </div>
  );
};

function addConfirmDialog(
  showRemoveProductsModal,
  setShowRemoveProductsModal,
  removeAllProductsItems
) {
  return (
    showRemoveProductsModal && (
      <Confirm
        title={translateLiteral('alerts.emptyCart')}
        cancelButtonText={translateLiteral('actions.cancel')}
        okButtonText={translateLiteral('actions.empty')}
        open={showRemoveProductsModal}
        handleCancel={() => setShowRemoveProductsModal(false)}
        handleOK={() => {
          setShowRemoveProductsModal(false);
          removeAllProductsItems();
        }}
      ></Confirm>
    )
  );
}

const mapStateToProps = state => {
  return {
    cart: state.orders.cart,
  };
};

export default connect(mapStateToProps, { removeAllProductsItems })(AppNavBar);
