import React from 'react';
import styles from './FormButton.module.css';
import { translateLiteral } from '../../literals/Literal';

const FormButton = ({ onClick, title, styleMode, disabled = false }) => {
  const styleName = styleMode ? styleMode : 'login';
  return (
    <div
      className={`${styles[`${styleName}-button`]} ${disabled &&
        styles.disabled}`}
      onClick={() => handleClick(disabled, onClick)}
    >
      <div className={styles[`${styleName}-label`]}>
        {title
          ? title
          : styleMode == 'register' || styleMode == 'register-fill'
          ? translateLiteral('actions.register').toUpperCase()
          : translateLiteral('actions.goIn').toUpperCase()}
      </div>
    </div>
  );
};

function handleClick(disabled, onClick) {
  if (!disabled) {
    onClick();
  }
}

export default FormButton;
