import idb from 'idb';
const dbName = 'font-oasis-db';
const currentVersion = 7;

export const dbPromise = idb.open(dbName, currentVersion, upgradeDB => {
  if (upgradeDB.oldVersion === 0) {
    createTables(upgradeDB);
  } else if (upgradeDB.oldVersion === 1) {
    Promise.resolve(upgradeDB).then(upgradeDB => {
      upgradeDB.createObjectStore('offers', { keyPath: 'id' });
    });
  } else if (upgradeDB.oldVersion === 2) {
    Promise.resolve(upgradeDB).then(upgradeDB => {
      upgradeDB.createObjectStore('cart', { keyPath: 'id' });
    });
  } else if (upgradeDB.oldVersion == 4) {
    Promise.resolve(upgradeDB).then(upgradeDB => {
      upgradeDB.createObjectStore('visits', { keyPath: 'id' });
    });
  }
});

const createTables = upgradeDB =>
  Promise.resolve(upgradeDB).then(upgradeDB => {
    upgradeDB.createObjectStore('cart', { keyPath: 'id' });
    upgradeDB.createObjectStore('offers', { keyPath: 'id' });
    upgradeDB.createObjectStore('orders', { keyPath: 'id' });
    upgradeDB.createObjectStore('user', { keyPath: 'id' });
    upgradeDB.createObjectStore('visits', { keyPath: 'id' });
    upgradeDB.createObjectStore('products', { keyPath: 'id' });
    upgradeDB.createObjectStore('addresses', { keyPath: 'id' });
    upgradeDB.createObjectStore('categories', { keyPath: 'id' });
  });

export function getDBTableKeys(tableName) {
  return getDBTable(tableName).then(allValues =>
    allValues.map(item => (tableName !== 'profile' ? item.primaryKey : item.id))
  );
}

function tableStore(tableName, readwrite = false) {
  // if(tableName){
  //   return
  // }
  return dbPromise.then(db => {
    var tx = db.transaction([tableName], readwrite ? 'readwrite' : 'readonly');
    return tx.objectStore(tableName);
  });
}

export function getDBTable(tableName) {
  return tableStore(tableName).then(store => {
    return store.getAll();
  });
}

export function getItem(tableName, key) {
  return tableStore(tableName).then(store => store.get(key));
}

export function putItems(tableName, items) {
  return tableStore(tableName, true).then(store => {
    if (items) {
      items.forEach(item => {
        var isSafari =
          /Safari/.test(navigator.userAgent) &&
          /Apple Computer/.test(navigator.vendor);
        if (!isSafari) {
          store.put(item);
        } else if (tableName == 'cart') {
          store.put(item);
        }
      });
    }
    return true;
  });
}

export function putItem(tableName, item) {
  return tableStore(tableName, true).then(store => store.put(item));
}

export function deleteItem(tableName, key) {
  return tableStore(tableName, true).then(store => store.delete(key));
}

export function deleteItems(tableName) {
  return tableStore(tableName, true).then(store => store.clear());
}
