import React, { useState, useEffect } from 'react';
import styles from './login.module.css'; // Import css modules stylesheet as styles
import FormInput from '../../core/ui/FormInput/FormInput';
import FormButton from '../../core/ui/FormButton/FormButton';
import AppText from '../../core/literals/AppText';
import LogoImage from '../../assets/logo.png';
import { translateLiteral } from '../../core/literals/Literal';
import ConditionsCheckbox from './ConditionsCheckbox/ConditionsCheckbox';
import { notify, ToastContainer } from '../../core/utils/ToastUtils';
import {
  fetchRegister,
  resetErrorMessage,
  fetchPartners,
  resetRegisterForm,
} from '../../core/redux/actions/AuthActions';
import { connect } from 'react-redux';
import { displayRegisterAppToolbar } from '../../core/redux/actions/AppActions';
import { useDebounce } from 'use-debounce';

const SignUp = props => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  props.displayRegisterAppToolbar();
  const { fetchPartners, fetchRegister, resetRegisterForm } = props;
  const [nif, setNif] = useState();
  const [nifDebounce] = useDebounce(nif, 1000);
  const [invalidNif, setInvalidNif] = useState(false);
  const [username, setUsername] = useState('');
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState();

  const doRegister = () => {
    window.blur();

    if (!termsAccepted) {
      notify(translateLiteral('login.signin.errorAcceptTerms'));
    } else if (invalidNif) {
      notify(translateLiteral('validations.errors.invalidNif'));
    } else if (invalidUsername) {
      notify(translateLiteral('validations.errors.invalidEmail'));
    } else if (invalidPassword) {
      notify(translateLiteral('validations.errors.invalidPassword'));
    } else if (password !== passwordConfirmation) {
      notify(translateLiteral('validations.errors.differentPasswords'));
    } else {
      fetchRegister(nifDebounce, selectedPartner, username, password);
    }
  };
  if (props.registerFail && props.errorMessage) {
    props.resetErrorMessage('register');
    notify(props.errorMessage);
  }

  useEffect(() => {
    if (nifDebounce) {
      fetchPartners(nifDebounce.toUpperCase());
    }
  }, [nifDebounce, fetchPartners]);

  useEffect(() => {
    return () => {
      setNif(null);
      setSelectedPartner(null);
      resetRegisterForm();
    };
  }, [resetRegisterForm]);
  return (
    <div className="d-flex flex-column">
      <div>
        <img alt="logo" className={styles['logo-small']} src={LogoImage} />
      </div>

      <AppText className={styles.title}>
        {translateLiteral('login.welcome')}
      </AppText>
      <AppText className={styles.subtitle}>
        {translateLiteral('login.signin.title')}
      </AppText>

      <FormInput
        type="nif"
        name={translateLiteral('inputs.nif')}
        onChange={setNif}
        invalidData={setInvalidNif}
      />
      {showPartnersList(props.partners || {})}
      {selectedPartner && (
        <FormInput
          type="username"
          name={translateLiteral('inputs.email')}
          onChange={setUsername}
          invalidData={setInvalidUsername}
        />
      )}
      {selectedPartner && (
        <FormInput
          type="password"
          name={translateLiteral('inputs.password')}
          onChange={setPassword}
          invalidData={setInvalidPassword}
        />
      )}
      {selectedPartner && (
        <FormInput
          type="password"
          name={translateLiteral('inputs.passwordConfirmation')}
          onChange={setPasswordConfirmation}
        />
      )}

      <ConditionsCheckbox updateState={setTermsAccepted} />

      <FormButton
        styleMode="register-fill"
        disabled={
          !selectedPartner ||
          !nifDebounce ||
          !username ||
          !password ||
          !passwordConfirmation ||
          !termsAccepted
        }
        onClick={doRegister}
      />
      <ToastContainer />
    </div>
  );

  function showPartnersList({ status, contacts }) {
    let partnersContent = null;
    if (status) {
      if (status == 'success') {
        if (contacts && contacts.length) {
          partnersContent = (
            <FormInput
              type="address"
              value={selectedPartner}
              onChange={value => {
                setSelectedPartner(value);
              }}
            >
              <option value={null}>{translateLiteral('inputs.address')}</option>
              {contacts.map((contact, idx) => (
                <option key={idx} value={contact.id}>
                  {contact.address}
                </option>
              ))}
            </FormInput>
          );
        } else {
          partnersContent = (
            <AppText className={styles.formInfo}>
              {translateLiteral('login.signin.noAdresses')}
            </AppText>
          );
        }
      } else {
        partnersContent = (
          <AppText className={styles.formInfo}>
            {translateLiteral('login.signin.errorNifNotFound')}
          </AppText>
        );
      }
    }
    return partnersContent;
  }
};

const mapStateToProps = state => {
  return {
    registerFail: state.auth.registerFail,
    errorMessage: state.auth.errorMessage,
    partners: state.auth.partners,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchRegister: (nif, id, email, password) => {
      return dispatch(fetchRegister(nif, id, email, password));
    },
    displayRegisterAppToolbar: (nif, email, password) => {
      return dispatch(displayRegisterAppToolbar(nif, email, password));
    },
    resetErrorMessage: type => {
      return dispatch(resetErrorMessage(type));
    },
    fetchPartners: nif => dispatch(fetchPartners(nif)),
    resetRegisterForm: () => dispatch(resetRegisterForm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
