import React from 'react';
import { Router, Route, Redirect } from 'react-router-dom';

// Screens
import SignIn from '../../screens/login/SignIn';
import SignUp from '../../screens/login/SignUp';
import Recover from '../../screens/login/Recover';
import HomeTabsController from '../../screens/HomeTabController/HomeTabController';
import OrderInvoice from '../../screens/OrderInvoice/OrderInvoice';
import Splash from '../../screens/splash/Splash';
import ProcessOrder from '../../screens/ProcessOrder/ProcessOrder';
import OrderFail from '../../screens/OrderFail/OrderFail';
import Profile from '../../screens/profile/Profile';
import history from './history';
import CancelOrder from '../../screens/CancelOrder/CancelOrder';

// Router Paths
export const uriLogin = '/login';
export const uriRecover = '/recover';
export const UriRegister = '/register';
export const uriProcessOrder = '/processOrder';
export const uriProcessRepeatOrder = '/processOrder/:id';
export const uriOrderFail = '/orderFail/:id';
export const uriHome = '/home';
export const uriProfile = '/profile';
const UriOrderInvoice = '/order/@/invoice';
export const UriCurrentOrderInvoice = '/invoice';
const uriCancelOrder = '/cancelOrder/:id';

let routeItems = [
  { path: '/', component: Splash, exact: true },
  { path: uriLogin, component: SignIn },
  { path: uriRecover, component: Recover },
  { path: UriRegister, component: SignUp },
  { path: UriOrderInvoice, component: OrderInvoice },
  { path: UriCurrentOrderInvoice, component: OrderInvoice },
  { path: uriProfile, component: Profile },
  { path: uriHome, component: HomeTabsController },
  { path: uriProcessRepeatOrder, component: ProcessOrder },
  { path: uriOrderFail, component: OrderFail },
  { path: uriCancelOrder, component: CancelOrder },
];
const publicRoutes = ['/', uriLogin, uriRecover, UriRegister];

class AppRouter extends React.Component {
  state = {
    items: routeItems,
    menuVisibility: false,
    store: {},
  };
  sectionOpened = () => {};

  render() {
    return (
      <Router history={history}>
        <div style={{ width: '100%', height: '100%' }}>
          {routeItems.map((route, idx) => (
            <Route
              path={route.path}
              key={idx}
              exact={route.exact}
              render={props =>
                checkAuth(route.path) ? (
                  <route.component {...props} />
                ) : (
                  <Redirect
                    to={{
                      pathname: uriLogin,
                      state: { from: props.location },
                    }}
                  />
                )
              }
            />
          ))}
        </div>
      </Router>
    );
  }
}

function checkAuth(path) {
  return (
    localStorage.getItem('token') || publicRoutes.some(route => route == path)
  );
}
export default AppRouter;
