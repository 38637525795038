import React, { Fragment, useEffect } from 'react';
import styles from './SegmentedControlList.module.css';
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import ProductsCategoryList from '../ProductsCategoryList/ProductsCategoryList';
import { connect } from 'react-redux';
import {
  fetchProducts,
  fetchCategories,
  selectCategory,
} from '../../../core/redux/actions/ProductsActions';

const CategoriesSegmentedList = ({
  categories,
  selectedId,
  itemsFetched,
  fetchProducts,
  fetchCategories,
  categoryIdToShow,
  ...props
}) => {
  const indexForId = id => categories.findIndex(item => item.id == id);
  const idForIndex = index => categories[index].id;

  useEffect(() => {
    if (categoryIdToShow) {
      props.selectCategory(categoryIdToShow);
    }
  }, [categoryIdToShow]);

  return (
    <Fragment>
      <div className={styles.container}>
        <SegmentedControl
          categories={categories}
          selectedIndex={indexForId(selectedId)}
          handleItemSelected={index => {
            props.selectCategory(idForIndex(index));
          }}
        />

        <ProductsCategoryList />
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    categories: state.products.categories.all,
    selectedId: state.products.categories.selectedId,
    itemsFetched: state.products.itemsFetched,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchProducts,
    fetchCategories,
    selectCategory,
  }
)(CategoriesSegmentedList);
