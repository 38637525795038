import React from 'react';
import { Provider } from 'react-redux';
import store from './core/redux/store';
import AppRouter from './core/router/AppRouter';
import './App.css';
import AppPage from './core/ui/AppPage/AppPage';
import CacheUpdater from 'cache-updater';
require('./apple-touch-icon.png');

function App() {
  CacheUpdater.init();
  return (
    <div className="App container-fluid">
      <Provider store={store}>
        <AppPage>
          <AppRouter />
        </AppPage>
      </Provider>
    </div>
  );
}

export default App;
