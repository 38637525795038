import {
  FETCH_CART,
  FETCHING_CART_SUCCESS,
  FETCHING_CREATE_ORDER_SUCCESS,
  FETCHING_CREATE_ORDER_FAIL,
  FETCH_ORDERS,
  FETCHING_ORDERS_SUCCESS,
  FETCHING_ORDERS_FAIL,
  FETCHING_CANCEL_ORDER_SUCCESS,
  FETCHING_CANCEL_ORDER_FAIL,
  SET_COMMENTS,
} from './types';

import store from '../store';
import { getDBTable } from '../../dbProvider';
import { requestProccessOrder } from '../../../api/ordersRequests/upsertOrder';
import { getLocalStorageValue } from '../../../api/authManager';
import { fetchAPI } from '.';
import { requestUserOrders } from '../../../api/userRequests';
import { fetchUserVisits } from './UserActions';
import { cancelOrder } from '../../../api/ordersRequests/cancelOrder';

export const fetchCart = () => {
  getDBTable('cart').then(items =>
    store.dispatch({
      type: FETCHING_CART_SUCCESS,
      action: items,
    })
  );
  return {
    type: FETCH_CART,
  };
};

export const repeatOrder = orderID => {};

export const processOrder = cartItems => {
  const partner_invoice_id = parseInt(getLocalStorageValue('partner_id'), 10);
  const partner_id = parseInt(getLocalStorageValue('partner_id'), 10);
  const partner_shipping_id = parseInt(getLocalStorageValue('address_id'), 10);
  const order_id = getLocalStorageValue('order_id');

  const cart = cartItems ? cartItems : store.getState().orders.cart;
  const { comments } = store.getState().orders;
  const order_line = cart
    ? cart.map(item => ({
        product_uom_qty: item.quantity ? item.quantity : item.product_uom_qty,
        product_id: item.product_id ? item.product_id : item.id,
      }))
    : [];

  const data = {
    partner_id,
    partner_shipping_id,
    order_line,
    partner_invoice_id,
  };

  if (comments && comments.length) {
    data.comments = comments;
  }

  if (order_id) {
    data.order_id = parseInt(order_id);
  }

  return requestProccessOrder(data)
    .then(result => {
      if (result.status == 'sucess') {
        fetchUserVisits();
        store.dispatch({
          type: FETCHING_CREATE_ORDER_SUCCESS,
        });
        return true;
      } else {
        store.dispatch({
          type: FETCHING_CREATE_ORDER_FAIL,
        });
        return false;
      }
    })
    .catch(err => {
      store.dispatch({
        type: FETCHING_CREATE_ORDER_FAIL,
      });
      return false;
    });
};

export const fetchUserOrders = () =>
  fetchAPI(
    requestUserOrders,
    FETCH_ORDERS,
    FETCHING_ORDERS_SUCCESS,
    FETCHING_ORDERS_FAIL,
    null,
    'orders'
  );

export function cancelOrderAction(orderId) {
  return cancelOrder(orderId)
    .then(result => {
      if (result.status == 'sucess') {
        fetchUserVisits();
        fetchUserOrders();
        store.dispatch({
          type: FETCHING_CANCEL_ORDER_SUCCESS,
        });
        return true;
      } else {
        store.dispatch({
          type: FETCHING_CANCEL_ORDER_FAIL,
        });
        return false;
      }
    })
    .catch(err => {
      store.dispatch({
        type: FETCHING_CANCEL_ORDER_FAIL,
      });
      return false;
    });
}

export const setComments = comments => {
  return store.dispatch({
    type: SET_COMMENTS,
    payload: comments,
  });
};
