import React, { useState } from 'react';
import styles from './FormInput.module.css';
import AppIcon from '../AppIcon/AppIcon';

const iconForField = type => {
  if (type == 'username') {
    return 'user';
  } else if (type == 'password') {
    return 'lock';
  } else if (type == 'nif') {
    return 'user';
  } else if (type == 'address') {
    return 'placeholder';
  }
};

const iconColor = '#B1B1B1';

const FormInput = ({
  type,
  name,
  id,
  initialValue,
  placeholder,
  onChange = () => {},
  invalidData = () => {},
  children,
  value,
}) => {
  let valueProp = initialValue ? { value: initialValue } : null;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={[styles.container, styles.containerError].join(' ')}>
      <div className={styles.leftContainer}>
        <AppIcon
          className={styles.input}
          color={iconColor}
          size={16}
          icon={iconForField(type)}
        />
      </div>

      <div className={styles.inputContainer}>
        {type == 'address' ? (
          <select
            className={styles.select}
            placeholder={placeholder}
            onChange={evt =>
              handleChange(evt.target.value, type, invalidData, onChange)
            }
            value={value}
          >
            {children}
          </select>
        ) : (
          <input
            className={styles.input}
            type={!showPassword ? type : 'text'}
            name={name}
            {...valueProp}
            id={id ? id : name ? name : type}
            placeholder={placeholder ? placeholder : name}
            onChange={evt =>
              handleChange(evt.target.value, type, invalidData, onChange)
            }
          />
        )}
      </div>
      {type == 'password' && (
        <div
          className={styles.showPassword}
          onClick={() => setShowPassword(!showPassword)}
        >
          <AppIcon
            color={iconColor}
            size={16}
            icon={showPassword ? 'visibility-1' : 'visibility'}
          />
        </div>
      )}
    </div>
  );
};

const handleChange = (value, type, invalidData, onChange) => {
  if (isValidData(type, value)) {
    invalidData(false);
    onChange(type == 'username' ? value.toLowerCase() : value);
  } else {
    invalidData(true);
  }
};

const isValidData = (type, value) => {
  if (type == 'username') {
    return value != '';
  } else if (type == 'password') {
    return value != '';
  } else if (type == 'nif') {
    return value != '';
    // return validateNif(value);
  } else {
    return true;
  }
};
export default FormInput;
