import React from 'react';
import banner from '../../../assets/banner.png';
import styles from './banner.module.css';

const BannerTouchable = ({ openProducts }) => (
  <div className={styles.container} onClick={() => openProducts()}>
    <div className={styles.bannerImage}>
      <img
        src={banner}
        alt="Banner"
        width="100%"
        style={{ maxHeight: '30%', height: '30%' }}
      />
    </div>
  </div>
);

export default BannerTouchable;
