import moment from 'moment';
import { putItems, putItem } from '../../dbProvider';
import {
  FETCHING_USER_ADDRESSES_SUCCESS,
  FETCHING_USER_CHANGE_PASSWORD_FAIL,
  FETCHING_USER_CHANGE_PASSWORD_SUCCESS,
  FETCHING_USER_DETAILS_FAIL,
  FETCHING_USER_DETAILS_SUCCESS,
  FETCHING_USER_VISITS_FAIL,
  FETCHING_USER_VISITS_SUCCESS,
  FETCH_USER_DETAILS,
  FETCH_USER_VISITS,
} from '../actions/types';

export default (
  state = {
    user: {},
    visits: [],
    nextDelivery: null,
    hasNextDelivery: null,
    email: '',
    passwordChangedSuccessfully: null,
    loadingData: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_USER_DETAILS:
      return {
        ...state,
        loadingData: true,
      };

    case FETCHING_USER_DETAILS_SUCCESS:
      localStorage.setItem('partner_id', action.action.partner_id);
      return {
        ...state,
        user: action.action,
        email: action.action.login,
        loadingData: false,
      };

    case FETCHING_USER_DETAILS_FAIL:
      return {
        ...state,
        loadingData: false,
      };

    case FETCH_USER_VISITS:
      return {
        ...state,
        visits: [],
        loadingData: true,
      };

    case FETCHING_USER_VISITS_SUCCESS:
      const visitsInfo = action.action;
      putItem('visits', { ...visitsInfo, id: Date.now() });
      visitsInfo.canEdit = canEdit(visitsInfo);
      visitsInfo.canCancel = canCancel(visitsInfo);
      return {
        ...state,
        visitsInfo: visitsInfo,
        loadingData: false,
      };

    case FETCHING_USER_VISITS_FAIL:
      return {
        ...state,
        visits: undefined,
        loadingData: false,
      };

    case FETCHING_USER_ADDRESSES_SUCCESS:
      const addresses = action.action.address;
      if (addresses) {
        putItems('addresses', addresses);
      }

      return {
        ...state,
        addresses,
        loadingData: false,
      };

    case FETCHING_USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChangedSuccessfully: true,
        loadingData: false,
      };

    case FETCHING_USER_CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        visits: undefined,
        passwordChangedSuccessfully: false,
        loadingData: false,
      };

    default:
      return state;
  }
};

function canEdit(visitsInfo) {
  let result = true;
  if (visitsInfo && visitsInfo.next_date) {
    result = moment(visitsInfo.next_date).diff(moment(), 'hours') > 0;
  }
  return result;
}

const hoursToNextDay = 11;
function canCancel(visitsInfo) {
  let result = true;
  if (visitsInfo && visitsInfo.next_date) {
    result =
      moment(visitsInfo.next_date).diff(moment(), 'hours') > hoursToNextDay;
  }
  return result;
}
