import React from 'react';
import styles from './Button.module.css';
import { translateLiteral } from '../../literals/Literal';
import { connect } from 'react-redux';
import { putProductsItem } from '../../../core/redux/actions/ProductsActions';
import history from '../../../core/router/history';
import { setComments } from '../../../core/redux/actions/OrderActions';

function EditOrderButton({
  inProgressOrder,
  visitsInfo,
  putProductsItem,
  products,
  setComments,
  blocked,
}) {
  return (
    <>
      <div
        className={`${styles.button} ${
          (visitsInfo && !visitsInfo.canEdit) || blocked ? styles.disabled : ''
        }`}
        onClick={() => {
          if ((!visitsInfo || visitsInfo.canEdit) && !blocked) {
            goToEditOrder();
          }
        }}
      >
        {translateLiteral('actions.editOrder').toUpperCase() +
          ' ' +
          inProgressOrder.name}
      </div>
      {visitsInfo && !visitsInfo.canEdit ? (
        <div className={styles.cantEdit}>
          {translateLiteral('home.cantEdit')}
        </div>
      ) : null}
    </>
  );

  function goToEditOrder() {
    if (!localStorage.getItem('order_id')) {
      localStorage.setItem('order_id', inProgressOrder.id);
      setComments(inProgressOrder.comments);
      inProgressOrder.line_data.forEach(item => {
        const product = products.find(product => product.id == item.product_id);
        if (product) {
          putProductsItem({
            ...product,
            quantity: parseFloat(item.product_uom_qty),
          });
        }
      });
    }
    history.push('/home?tab=1');
  }
}

const mapStateToProps = state => {
  return {
    products: state.products.products.all,
  };
};

export default connect(mapStateToProps, { putProductsItem, setComments })(
  EditOrderButton
);
