import {
  FETCH_OFFERS,
  FETCHING_OFFERS_SUCCESS,
  FETCH_PRODUCTS,
  FETCHING_PRODUCTS_SUCCESS,
  FETCHING_PRODUCTS_FAIL,
  PUT_PRODUCTS_ITEM,
  REMOVE_PRODUCTS_ITEM,
  REMOVE_ALL_PRODUCTS_ITEM,
  FETCH_CATEGORIES,
  FETCHING_CATEGORIES_SUCCESS,
  FETCHING_CATEGORIES_FAIL,
  SELECT_CATEGORY,
} from './types';
import store from '../store';
import { offers } from '../../../api/productsRequests';
import { fetchAPI } from '.';
import {
  requestCategories,
  requestProducts,
} from '../../../api/productsRequests';

export const fetchOffers = () => {
  store.dispatch(offers());

  return {
    type: FETCH_OFFERS,
  };
};

export const fetchingOffersSuccess = offers => {
  return {
    type: FETCHING_OFFERS_SUCCESS,
    action: offers,
  };
};

export const selectCategory = id => {
  return {
    type: SELECT_CATEGORY,
    action: id,
  };
};

export const fetchCategories = () =>
  fetchAPI(
    requestCategories,
    FETCH_CATEGORIES,
    FETCHING_CATEGORIES_SUCCESS,
    FETCHING_CATEGORIES_FAIL,
    null,
    'categories'
  );

export const fetchProducts = () =>
  fetchAPI(
    requestProducts,
    FETCH_PRODUCTS,
    FETCHING_PRODUCTS_SUCCESS,
    FETCHING_PRODUCTS_FAIL,
    null,
    'products'
  );

export const putProductsItem = item => {
  // store in database
  return {
    type: PUT_PRODUCTS_ITEM,
    action: item,
  };
};

export const removeProductsItem = item => {
  // store in database
  return {
    type: REMOVE_PRODUCTS_ITEM,
    action: item,
  };
};

export const removeAllProductsItems = () => {
  // store in database
  return {
    type: REMOVE_ALL_PRODUCTS_ITEM,
  };
};
