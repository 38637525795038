import React from 'react';
import styles from './InvoiceFinishOrder.module.css';
import history from '../../../core/router/history';
import * as safeAreaInsets from 'safe-area-insets';
import { translateLiteral } from '../../../core/literals/Literal';
import InvoiceCancelOrder from '../InvoiceCancelOrder/InvoiceCancelOrder';

const InvoiceFinishOrder = ({ total, itemsCount = 0, visitsInfo, blocked }) => {
  const inProgressOrder = localStorage.getItem('order_id');
  return (
    <div style={{ marginBottom: safeAreaInsets.bottom }}>
      {inProgressOrder && visitsInfo && visitsInfo.canCancel && (
        <div className={styles.topButton}>
          <InvoiceCancelOrder visitsInfo={visitsInfo} />
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.labelsContainer}>
          <div className={styles.title}> {total.toFixed(2)} €</div>
          <div className={styles.subtitle}>
            {itemsCount} {translateLiteral('orders.products')}
          </div>
          <div className={styles.priceInfo}>
            {translateLiteral('orders.noTaxes')}
          </div>
        </div>
        {getButton(itemsCount, visitsInfo, blocked)}
      </div>
    </div>
  );
};

function getButton(itemsCount, visitsInfo, blocked) {
  const canEdit = !blocked && (visitsInfo ? visitsInfo.canEdit : true);
  let action = null;
  let text = null;
  if (itemsCount) {
    text = localStorage.getItem('order_id')
      ? 'orders.updateOrder'
      : 'orders.completeOrder';
    action = goProccesOrder;
  } else {
    text = 'orders.addProducts';
    action = goToProducts;
  }
  return (
    <div
      className={`${styles.button} ${!canEdit ? styles.disabled : ''}`}
      onClick={() => {
        if (canEdit) {
          action();
        }
      }}
    >
      {translateLiteral(text).toUpperCase()}
    </div>
  );

  function goProccesOrder() {
    history.push('/processOrder/0');
  }

  function goToProducts() {
    history.push('/home?tab=1');
  }
}

export default InvoiceFinishOrder;
