import React from 'react';
import styles from './Comments.module.css';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { setComments } from '../../../core/redux/actions/OrderActions';
import { translateLiteral } from '../../../core/literals/Literal';

function Comments({ comments, setComments }) {
  return (
    <div className={styles.commentsContainer}>
      <Input
        className={styles.textarea}
        placeholder={translateLiteral('orders.commentsPlaceholder')}
        type="textarea"
        value={comments || ''}
        onChange={event => {
          setComments(event.target.value);
        }}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    comments: state.orders.comments,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setComments: comments => {
      return dispatch(setComments(comments));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
