import React, { useEffect } from 'react';
import { uriLogin, uriHome } from '../../core/router/AppRouter';
import styles from './Splash.module.css';
import splashImage from '../../assets/splash.png';
import { connect } from 'react-redux';

import { hideAppToolbar } from '../../core/redux/actions/AppActions';
import { AuthMode, isAuthenticated } from '../../api/authManager';
import history from '../../core/router/history';

const Splash = ({ hideAppToolbar, authStatus, itemsFetched }) => {
  hideAppToolbar();

  useEffect(() => {
    if (itemsFetched || !isAuthenticated()) {
      const path = redirectPath(authStatus);
      history.push(path);
    }
  }, [itemsFetched, authStatus]);

  return (
    <div className={styles.container}>
      <div className={styles.splashImageContainer}>
        <img className={styles.splashImage} src={splashImage} alt="Banner" />
      </div>
    </div>
  );
};

function redirectPath(authStatus) {
  switch (authStatus) {
    case AuthMode.NOT_AUTHENTICATED:
      return uriLogin;

    case AuthMode.AUTHENTICATED:
      return uriHome + '?tab=0';

    case AuthMode.EXPIRED:
      return uriLogin;

    default:
      break;
  }
}
const mapStateToProps = state => {
  return {
    authStatus: state.auth.auth_status,
    itemsFetched: state.products.itemsFetched,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideAppToolbar,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Splash);
