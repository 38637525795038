import React from 'react';
import { connect } from 'react-redux';
import styles from './ProductsCount.module.css';
import OrderButton from '../../../core/ui/OrderButton/OrderButton';
import { translateLiteral } from '../../../core/literals/Literal';
import * as safeAreaInsets from 'safe-area-insets';

const calculateAmount = (items) => {
  const prices = items.map(
    (item) => parseFloat(item.list_price) * item.quantity
  );
  const amount = prices.length ? prices.reduce((a, b) => a + b) : 0;
  return amount;
};

const calculateProductsCount = (items) => {
  const prices = items.map((item) => item.quantity);
  const amount = prices.length ? prices.reduce((a, b) => a + b) : 0;
  return amount;
};

const ProductsCount = ({ ...props }) => {
  const amount = calculateAmount(props.cart);
  return (
    <div className={styles.container} style={{ bottom: safeAreaInsets.bottom }}>
      <div className={styles.counterContainer}>
        <div className={styles.title}>{amount.toFixed(2)} €</div>
        <div className={styles.subtitle}>
          {props.cart ? calculateProductsCount(props.cart) : 0} Productos
        </div>
        <div className={styles.priceInfo}>
          {translateLiteral('orders.noTaxes')}
        </div>
      </div>

      <div style={{ marginRight: '8px' }}>
        <OrderButton title={translateLiteral('actions.doOrder')} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.orders.cart,
  };
};

export default connect(mapStateToProps)(ProductsCount);
