import { fetchWithTimeout, host } from './apiClient';
import store from '../core/redux/store';
import { fetchingOffersSuccess } from '../core/redux/actions/ProductsActions';

const uriAllCategories = host + '/categories';
const uriAllProducts = host + '/products';

export function offers() {
  return new Promise((resolve, reject) => {
    store.dispatch(fetchingOffersSuccess([]));
  });
}
const requestCategories = () => {
  return fetchWithTimeout(uriAllCategories);
};

const requestProducts = () => {
  return fetchWithTimeout(uriAllProducts);
};

export { requestCategories, requestProducts };
