import React, { useState, useEffect } from 'react';
import styles from './CancelOrder.module.css';
import history from '../../core/router/history';
import { connect } from 'react-redux';
import { hideAppToolbar } from '../../core/redux/actions/AppActions';
import { cancelOrderAction } from '../../core/redux/actions/OrderActions';
import { translateLiteral } from '../../core/literals/Literal';
import Loader from 'react-loader-spinner';

function CancelOrder({ hideAppToolbar, match }) {
  hideAppToolbar();
  const [processing, setProcessing] = useState(true);
  const [cancelationResult, setCancelationResult] = useState(false);

  useEffect(() => {
    cancelOrderEffect({ match, setProcessing, setCancelationResult });
  }, [match]);

  return (
    <div
      className={`${styles.container} ${
        !processing && !cancelationResult ? styles.error : ''
      }`}
    >
      {processing && (
        <Loader type="TailSpin" color="#61A0ED" height="90" width="90" />
      )}

      <div className={styles.title}>
        {processing
          ? translateLiteral('orders.cancelingOrder')
          : cancelationResult
          ? translateLiteral('orders.cancelationProcessed')
          : translateLiteral('orders.cancelationFailed')}
      </div>
      {!processing && (
        <div
          className={`${styles.finishButton} ${
            !processing && !cancelationResult ? styles.finishButtonError : ''
          }`}
          onClick={() => {
            goBack();
          }}
        >
          {translateLiteral('actions.back').toUpperCase()}
        </div>
      )}
    </div>
  );
}

function cancelOrderEffect({ match, setProcessing, setCancelationResult }) {
  setProcessing(true);

  const orderId = match.params.id;
  cancelOrderAction(orderId)
    .then(success => {
      setProcessing(false);
      setCancelationResult(success);
    })
    .catch(err => {
      setCancelationResult(false);
      setProcessing(false);
    });
}

function goBack() {
  history.push('/home?tab=0');
}

export default connect(null, {
  hideAppToolbar,
})(CancelOrder);
