import { fetchWithTimeout, host } from './apiClient';

const uriUserDetails = host + '/user';
const uriUserOrders = host + '/user/orders';
const uriUserOrderDetail = id => host + '/authenticate';
const uriUserVisits = host + '/user/visits';
const uriUserAddresses = host + '/user/address';
const uriUserChangePassword = host + '/user/change-password';
const uriUserResetPassword = host + '/user/reset-password';

const requestUserData = () => fetchWithTimeout(uriUserDetails);
const requestUserOrders = () => fetchWithTimeout(uriUserOrders);
const requestUserOrderDetail = id => fetchWithTimeout(uriUserOrderDetail(id));
const requestUserVisits = () => fetchWithTimeout(uriUserVisits);
const requestUserResetPassword = () => fetchWithTimeout(uriUserResetPassword);

const requestUserAddresses = () =>
  fetchWithTimeout(uriUserAddresses).then(result => {
    let first =
      result.address && result.address.length ? result.address[0] : null;
    if (first) {
      localStorage.setItem('address_id', first.id);
    }
    return result;
  });

const requestUserChangePassword = ({ oldPassword, newPassword }) =>
  fetchWithTimeout(uriUserChangePassword, {
    method: 'POST',
    body: JSON.stringify({
      oldPassword,
      password: newPassword,
    }),
  });

export {
  requestUserData,
  requestUserOrders,
  requestUserOrderDetail,
  requestUserVisits,
  requestUserAddresses,
  requestUserChangePassword,
  requestUserResetPassword,
};
