import React from 'react';
import styles from './OrderFail.module.css';
import AppIcon from '../../core/ui/AppIcon/AppIcon';
import history from '../../core/router/history';
import { get } from 'lodash';

const OrderFail = ({ finishAction, ...props }) => {
  const repeatCreateOrder = () => {
    const orderId = get(props, 'match.params.id');
    if (orderId) {
      history.push(`/processOrder/${orderId}`);
    } else {
      console.log("NO")
    }
  };
  
  return (
    <div className={styles.container}>
      <AppIcon color="#ffffff" size={92} icon="check" />

      <div className={styles.title}> PEDIDO NO REALIZADO</div>
      <div className={styles.subtitle}>
        No ha sido posible realizar el pedido
      </div>
      <div
        className={styles.finishButton}
        onClick={() => repeatCreateOrder()}
      >
        REPETIR
      </div>
      <div
        className={styles.cancel}
        onClick={() => {
          history.push('/home');
        }}
      >
        {' '}
        CANCELAR
      </div>
    </div>
  );
};

export default OrderFail;
