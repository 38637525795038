import { fetchWithTimeout, host } from '../apiClient';

const uriCancelOrder = host + '/order/cancel-order';

export function cancelOrder(orderId) {
  return fetchWithTimeout(uriCancelOrder, {
    method: 'POST',
    body: JSON.stringify({ order_id: orderId }),
  });
}
