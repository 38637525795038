import React from 'react';
import styles from './Button.module.css';
import { translateLiteral } from '../../literals/Literal';
import history from '../../../core/router/history';

const NewOrderButton = ({ blocked }) => {
  return (
    <div
      className={`${styles.button} ${blocked ? styles.disabled : ''}`}
      onClick={() => {
        if (!blocked) {
          history.push('/home?tab=1');
        }
      }}
    >
      {translateLiteral('actions.newOrder').toUpperCase()}
    </div>
  );
};

export default NewOrderButton;
