import { fetchWithTimeout, host } from '../apiClient';

const uriCreateOrder = host + '/create-order';
const uriUpdateOrder = host + '/order/update-order';

export function requestProccessOrder(data) {
  return fetchWithTimeout(data.order_id ? uriUpdateOrder : uriCreateOrder, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}
