import history from './history';
import { uriProfile, UriCurrentOrderInvoice } from './AppRouter';
import { hideAppToolbar } from '../redux/actions/AppActions';
import store from '../redux/store';
import { logoutUser } from '../../api/authManager';

export const openCartInvoice = () => {
  history.push(UriCurrentOrderInvoice);
};
export const openProfile = () => {
  history.push(uriProfile);
};

export const openLogout = () => {
  store.dispatch(hideAppToolbar());
  logoutUser();
};

export default { openCartInvoice, openProfile, openLogout };
