import { displayAppToolbar } from './AppActions';
import {
  registerUser,
  recoverUser,
  loginUser,
  getPartners,
} from '../../../api/authManager';
import {
  FETCH_REGISTER,
  FETCHING_REGISTER_SUCCESS,
  FETCHING_REGISTER_FAIL,
  FETCH_LOGIN,
  FETCHING_LOGIN_SUCCESS,
  FETCHING_LOGIN_FAIL,
  FETCH_RECOVER,
  FETCH_PARTNERS,
  FETCHING_PARTNERS_SUCCESS,
  FETCHING_PARTNERS_FAIL,
  RESET_REGISTER_FORM,
} from './types';
import store from '../store';
import history from '../../router/history';
import {
  fetchUserDetails,
  fetchUserVisits,
  fetchUserAddresses,
} from './UserActions';
import { fetchUserOrders } from './OrderActions';
import { fetchProducts, fetchCategories } from './ProductsActions';

export const resetErrorMessage = type => {
  return {
    type: 'RESET_ERROR_MESSAGE',
    action: type,
  };
};

export const fetchLogin = (username, password) => {
  loginUser(username, password).then(success => {
    if (!success) {
      history.replace('/login');
    }
  });

  return {
    type: FETCH_LOGIN,
  };
};

export const fetchingLoginSuccess = user => {
  localStorage.setItem('token', user.token);
  store.dispatch(displayAppToolbar());

  history.replace('/home?tab=0');

  fetchUserDetails();
  fetchUserOrders();
  fetchUserVisits();
  fetchUserAddresses();
  fetchProducts();
  fetchCategories();

  return {
    type: FETCHING_LOGIN_SUCCESS,
    action: user,
  };
};

export const fetchingLoginFail = () => {
  return {
    type: FETCHING_LOGIN_FAIL,
  };
};

export const fetchRecover = email => {
  return recoverUser(email);
};

export const fetchRegister = (nif, id, email, password) => {
  registerUser(nif, id, email, password);

  return {
    type: FETCH_REGISTER,
  };
};

export const fetchingRegisterSuccess = (email, password, user) => {
  store.dispatch(
    loginUser(email, password).then(success => {
      if (!success) {
        history.push('/login');
      }
    })
  );

  return {
    type: FETCHING_REGISTER_SUCCESS,
    action: user,
  };
};

export const fetchingRegisterFail = () => {
  return {
    type: FETCHING_REGISTER_FAIL,
  };
};

export const fetchPartners = nif => {
  getPartners(nif);

  return {
    type: FETCH_PARTNERS,
  };
};

export const fetchingPartnersSuccess = partners => {
  return {
    type: FETCHING_PARTNERS_SUCCESS,
    data: partners,
  };
};

export const fetchingPartnersFail = () => {
  return {
    type: FETCHING_PARTNERS_FAIL,
  };
};

export const resetRegisterForm = () => {
  return {
    type: RESET_REGISTER_FORM,
  };
};
