import React, { useState } from 'react';
import { connect } from 'react-redux';
import LogoImage from '../../assets/logo.png';
import { translateLiteral } from '../../core/literals/Literal';
import { displayRecoverAppToolbar } from '../../core/redux/actions/AppActions';
import {
  fetchRecover,
  resetErrorMessage,
} from '../../core/redux/actions/AuthActions';
import FormButton from '../../core/ui/FormButton/FormButton';
import FormInput from '../../core/ui/FormInput/FormInput';
import styles from './login.module.css'; // Import css modules stylesheet as styles
import {
  notify,
  ToastContainer,
  notifySuccess,
} from '../../core/utils/ToastUtils';

const Recover = props => {
  props.displayRecoverAppToolbar();

  const [username, setUsername] = useState('');
  const [invalidUsername, setInvalidUsername] = useState(true);

  const doRecover = () => {
    window.blur();
    if (invalidUsername) {
      notify(translateLiteral('validations.errors.invalidEmail'));
    } else {
      fetchRecover(username)
        .then(res => {
          if (res.error || res.status == 'fail') {
            notify(translateLiteral('validations.errors.recoverFail'));
          } else {
            notifySuccess(translateLiteral('login.recover.success'));
          }
        })
        .catch(() =>
          notify(translateLiteral('validations.errors.recoverFail'))
        );
    }
  };

  if (props.recoverFail && props.errorMessage) {
    resetErrorMessage('recover');
    notify(props.errorMessage);
  }

  return (
    <div className="d-flex flex-column">
      <div>
        <img alt="logo" className={styles['logo-small']} src={LogoImage} />
      </div>

      <div className={styles.title}>
        {translateLiteral('login.recover.title')}
      </div>
      <div
        className={styles.subtitle}
        style={{ marginTop: 26, marginBottom: 38 }}
      >
        {translateLiteral('login.recover.subtitle')}
      </div>

      <FormInput
        type="username"
        name={translateLiteral('inputs.email')}
        onChange={setUsername}
        invalidData={setInvalidUsername}
      />

      <div
        style={{ marginBottom: 30 }}
      >
        <FormButton
          title={translateLiteral('actions.send')}
          onClick={() => doRecover()}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    recoverFail: state.auth.recoverFail,
    errorMessage: state.auth.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    displayRecoverAppToolbar: () => {
      return dispatch(displayRecoverAppToolbar());
    },
    resetErrorMessage: type => {
      return dispatch(resetErrorMessage(type));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Recover);
