import { fetchWithTimeout, host } from './apiClient';

const uriGetPartner = host + '/get-partner';
const uriRegisterUser = host + '/create-user';
const uriAuthenticate = host + '/authenticate';
const uriResetUserPassword = host + '/user/reset-password';

const fetchGetPartners = vat => {
  const uri =
    uriGetPartner +
    '?' +
    new URLSearchParams(Object.entries({ vat })).toString();
  return fetchWithTimeout(uri).then(res => {
    if (res) {
      return res;
    } else {
      return null;
    }
  });
};

const fetchRegisterUser = (partnerId, email, password) =>
  fetchWithTimeout(uriRegisterUser, {
    method: 'post',
    body: JSON.stringify({
      partner_id: partnerId,
      email,
      password,
    }),
  })
    .then(res => {
      if (res.status == 'error') {
        return res;
      } else {
        localStorage.setItem('user_id', res.user_id);
        localStorage.setItem('email', email);
        localStorage.setItem('partner_invoice_id', partnerId);
        return res;
      }
    })
    .catch(res => {
      return { status: 'error' };
    });

const fetchLoginUser = (username, password) =>
  fetchWithTimeout(uriAuthenticate, {
    method: 'post',
    body: JSON.stringify({
      username,
      password,
    }),
  }).then(res => {
    localStorage.setItem('email', username);
    return res;
  });

const fetchRecoverUser = email =>
  fetchWithTimeout(uriResetUserPassword, { queryParams: { email } });

export {
  fetchGetPartners,
  fetchRegisterUser,
  fetchLoginUser,
  fetchRecoverUser,
};
