import { putItem, putItems } from '../../dbProvider';
import {
  FETCHING_OFFERS_FAIL,
  FETCHING_OFFERS_SUCCESS,
  FETCHING_PRODUCTS_FAIL,
  FETCHING_PRODUCTS_SUCCESS,
  FETCH_ERROR_HIDE,
  FETCH_ERROR_SHOW,
  FETCH_OFFERS,
  FETCH_PRODUCTS,
  FETCH_CATEGORIES,
  FETCHING_CATEGORIES_SUCCESS,
  FETCHING_CATEGORIES_FAIL,
  SELECT_CATEGORY,
} from '../actions/types';

const defaultCategory = 3;

export default (
  state = {
    list: undefined,
    current: undefined,
    offers: undefined,
    itemsFetched: false,
    categories: {
      all: [],
      selectedId: null,
    },
    products: {
      all: [],
      filtered: [],
      currentCategory: [],
    },
    cart: null,
    loadingData: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return {
        ...state,
        loadingData: true,
      };

    case FETCHING_CATEGORIES_SUCCESS:
      const categories = action.action;
      putItems('categories', categories);
      const firtCategory = categories.length && categories[0];
      return {
        ...state,
        categories: {
          all: categories,
          selectedId: firtCategory ? firtCategory.id : null,
        },
        loadingData: false,
      };

    case FETCHING_CATEGORIES_FAIL:
      return {
        ...state,
        list: [],
        loadingData: false,
      };

    case SELECT_CATEGORY:
      const categoryId = action.action;
      const categoryItems = state.products.all.filter(
        product => product.categ_id == categoryId
      );
      return {
        ...state,
        categories: { ...state.categories, selectedId: categoryId },
        products: { ...state.products, currentCategory: categoryItems },
      };

    case FETCH_PRODUCTS:
      return {
        ...state,
        list: [],
        current: undefined,
        loadingData: true,
        itemsFetched: false,
      };
    case FETCHING_PRODUCTS_SUCCESS:
      const products = action.action;
      putItems('products', products);
      const category = state.categories.selectedId || defaultCategory;
      const currentCategory = products.filter(
        product => product.categ_id == category
      );
      return {
        ...state,
        products: {
          all: action.action,
          currentCategory,
          loadingData: false,
        },
        itemsFetched: true,
        loadingData: false,
      };

    case FETCHING_PRODUCTS_FAIL:
      return {
        ...state,
        list: [],
        loadingData: false,
      };

    case FETCH_OFFERS:
      return {
        ...state,
        loadingData: true,
      };

    case FETCHING_OFFERS_SUCCESS:
      putItem('offers', action.action);

      return {
        ...state,
        offert: action.action,
        offers: action.action,
        loadingData: false,
      };

    case FETCHING_OFFERS_FAIL:
      return {
        ...state,
        loadingData: false,
      };

    case FETCH_ERROR_HIDE:
      return {
        ...state,
        showToast: false,
        errorMessage: null,
      };

    case FETCH_ERROR_SHOW:
      return {
        ...state,
        showToast: true,
      };

    default:
      return state;
  }
};
