import store from '../store';
import { getDBTable } from '../../dbProvider';

const fetchAPI = (
  request,
  requestType,
  successType,
  faiType,
  requestParams,
  dbTableName
) => {
  store.dispatch({ type: requestType });
  return request(requestParams)
    .then(result => {
      if (result && !result.error) {
        fetchingSuccess(successType, result);
      } else {
        fetchingFail(faiType, dbTableName, successType);
      }
    })
    .catch(error => {
      fetchingFail(faiType, dbTableName, successType);
    });
};

const fetchingSuccess = (type, data) => {
  store.dispatch({
    type: type,
    action: data,
  });
};

const fetchingFail = (type, tableName, successType) => {
  if (tableName) {
    getDBTable(tableName).then(items => {
      fetchingSuccess(successType, items);
    });
  }
  store.dispatch({
    type: type,
  });
};

export { fetchAPI, fetchingSuccess, fetchingFail };
