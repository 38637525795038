import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import OrderReducer from './OrderReducer';
import ProductsReducer from './ProductsReducer';
import AppReducer from './AppReducer';
import UserReducer from './UserReducer';

export default combineReducers({
  auth: AuthReducer,
  orders: OrderReducer,
  products: ProductsReducer,
  app: AppReducer,
  user: UserReducer,
});
