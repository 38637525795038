import React, { useState, useEffect, useRef } from 'react';
import styles from './QuantityCounter.module.css';
import { connect } from 'react-redux';
import { translateLiteral } from '../../../core/literals/Literal';

const QuantityCounter = props => {
  const [quantity, setQuantity] = useState(0);
  const { productId, valueChanged, cart, products } = props;
  const [product, setProduct] = useState(0);

  useEffect(() => {
    const filteredProducts = cart
      ? cart.filter(product => product.id == productId)
      : [];
    const initialProduct = filteredProducts.length ? filteredProducts[0] : null;
    setQuantity(initialProduct ? initialProduct.quantity : 0);
  }, [productId, cart]);

  useEffect(() => {
    if (productId && products) {
      setProduct(products.find(product => product.id == productId));
    }
  }, [productId, products]);

  const handleDecrement = () => {
    if (quantity > 0) {
      let newQuantity = quantity - 1;
      if (product && product.minimum_qty && product.minimum_qty > newQuantity) {
        newQuantity = 0;
      }
      setQuantity(newQuantity);
      valueChanged(newQuantity);
    }
  };

  const handleIncrement = () => {
    let newQuantity = quantity + 1;
    if (product && product.minimum_qty && product.minimum_qty > newQuantity) {
      newQuantity = product.minimum_qty;
    }
    setQuantity(newQuantity);
    valueChanged(newQuantity);
  };
  return (
    <div className="flex-column">
      {product && product.minimum_qty ? (
        <div className={styles.minimumQuantity}>
          {translateLiteral('products.minimumQuantity')} {product.minimum_qty}
        </div>
      ) : null}
      <div className={styles.container}>
        <div className={styles.button} onClick={handleDecrement}>
          -
        </div>
        <div className={styles.title}>{quantity}</div>
        <div className={styles.button} onClick={handleIncrement}>
          +
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    cart: state.orders.cart,
    products: state.products.products.all,
  };
};

export default connect(mapStateToProps)(QuantityCounter);
