import React from 'react';
import styles from './ProductItemInfo.module.css';
import { connect } from 'react-redux';

const currency = '€';

const ProductItemInfo = ({ item, ...props }) => {
  const quantity = quantityForProduct(item.product_uom_qty);
  const productAmount = String(quantity * item.price_unit) + currency;

  const imageForProduct = id => {
    const filteredProducts = props.products.filter(product => product.id == id);
    const path = filteredProducts.length ? filteredProducts[0].image_url : null;
    return <img src={path} alt={'Product' + id} width="100%" />;
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon}>{imageForProduct(item.product_id)}</div>
      <div className={styles.label}>
        {quantity} x {item.name}
      </div>
      <div className={styles.price}>{productAmount}</div>
    </div>
  );
};

const quantityForProduct = quantity => {
  const value = parseFloat(quantity);
  return value.toFixed(0);
};

const mapStateToProps = state => {
  return {
    products: state.products.products.all,
  };
};

export default connect(mapStateToProps)(ProductItemInfo);
