import React, { useEffect } from 'react';
import InvoiceAmountData from './InvoiceAmountData/InvoiceAmountData';
import InvoiceFinishOrder from './InvoiceFinishOrder/InvoiceFinishOrder';
import InvoiceItem from './InvoiceItem/InvoiceItem';
import styles from './OrderInvoice.module.css';
import { connect } from 'react-redux';
import { displayOrderAppToolbar } from '../../core/redux/actions/AppActions';
import {
  removeProductsItem,
  putProductsItem,
} from '../../core/redux/actions/ProductsActions';
import AppIcon from '../../core/ui/AppIcon/AppIcon';
import AppText from '../../core/literals/AppText';
import { translateLiteral } from '../../core/literals/Literal';
import { fetchCart } from '../../core/redux/actions/OrderActions';
import Comments from './Comments/Comments';

const OrderInvoice = ({
  cart,
  products,
  openProducts,
  visitsInfo,
  user,
  ...props
}) => {
  useEffect(() => {
    props.displayOrderAppToolbar();
  });

  if (!cart) {
    props.fetchCart();
  }

  const amount = cart ? calculateAmount(cart) : 0;
  const count = cart
    ? cart.map(item => item.quantity).reduce((a, b) => a + b, 0)
    : 0;
  const total = amount;
  console.log(user);
  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        {count ? (
          cart && (
            <>
              {cart.map((item, index) => (
                <InvoiceItem
                  key={index}
                  productId={item.id}
                  title={item.name}
                  price={parseFloat(item.list_price)}
                  quantity={item.quantity}
                  valueChanged={value =>
                    handleProductToShoppingCart(props, item, value)
                  }
                  packItems={item.pack_items}
                  products={products}
                />
              ))}
              <InvoiceAmountData total={total} />
            </>
          )
        ) : (
          <EmptyInvoice />
        )}
        <Comments></Comments>
      </div>
      <InvoiceFinishOrder
        visitsInfo={visitsInfo}
        total={total}
        itemsCount={count}
        openProducts={openProducts}
        blocked={user.blocked}
      />
    </div>
  );
};

const calculateAmount = items => {
  const prices = items.map(item => item.quantity * parseFloat(item.list_price));
  const amount = prices.length ? prices.reduce((a, b) => a + b) : 0;
  return amount;
};

const handleProductToShoppingCart = (props, item, quantity) => {
  if (quantity == 0) {
    props.removeProductsItem(item);
  } else {
    props.putProductsItem({ ...item, quantity: quantity });
  }
};

const mapStateToProps = state => {
  return {
    cart: state.orders.cart,
    products: state.products.products.all,
    visitsInfo: state.user.visitsInfo,
    user: state.user.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    displayOrderAppToolbar: () => {
      return dispatch(displayOrderAppToolbar());
    },
    removeProductsItem: item => {
      return dispatch(removeProductsItem(item));
    },
    putProductsItem: item => {
      return dispatch(putProductsItem(item));
    },
    fetchCart: item => {
      return dispatch(fetchCart());
    },
  };
};

const EmptyInvoice = () => {
  return (
    <div className="w-100 h-75 d-flex flex-column justify-content-center align-items-center">
      <AppIcon color="#B1B1B1" size={95} icon="shopping-cart" />
      <AppText className="mt-2">
        {translateLiteral('orders.empty_cart')}
      </AppText>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderInvoice);
