import React from 'react';
import styles from './InvoiceAmountData.module.css';

const InvoiceAmountItem = ({ title, value, titleStyle }) => {
  const className =
    titleStyle == 'title'
      ? styles.title
      : titleStyle == 'subtitle'
      ? styles.subtitle
      : styles.title;
  return (
    <div className={styles.itemContainer}>
      <div className={className} style={{ marginLeft: '23px' }}>
        {title}
      </div>
      <div className={className} style={{ marginRight: '23px' }}>
        {value} €
      </div>
    </div>
  );
};

const InvoiceAmountData = ({ total }) => {
  return (
    <div className={styles.container}>
      <div style={{ marginTop: '17px' }}>
        <InvoiceAmountItem
          title="Total"
          value={total.toFixed(2)}
          titleStyle="title"
        />
      </div>
      <div className={styles.horizontalLine} />
    </div>
  );
};

export default InvoiceAmountData;
