import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './AppPage.module.css';
import AppNavBar from '../AppBar/AppNavBar';
import LoadingView from '../LoadingView/LoadingView';
import { isAuthenticated } from '../../../api/authManager';
import { fetchUserOrders } from '../../redux/actions/OrderActions';
import {
  fetchUserVisits,
  fetchUserAddresses,
  fetchUserDetails,
} from '../../redux/actions/UserActions';
import {
  fetchProducts,
  fetchCategories,
} from '../../redux/actions/ProductsActions';

const AppPage = ({
  children,
  showToolbar,
  showBackButton,
  showLogoutButton,
  showRegisterMode,
  showRecoverMode,
  showOrderMode,
  showProfileMode,
  fetchUserOrders,
  fetchUserVisits,
  fetchUserAddresses,
  fetchProducts,
  fetchCategories,
  fetchUserDetails,
}) => {
  useEffect(() => {
    if (isAuthenticated()) {
      fetchUserOrders();
      fetchUserVisits();
      fetchUserAddresses();
      fetchProducts();
      fetchCategories();
      fetchUserDetails();
    }
    localStorage.removeItem('order_id');
  }, [
    fetchUserOrders,
    fetchUserVisits,
    fetchUserAddresses,
    fetchProducts,
    fetchCategories,
    fetchUserDetails,
  ]);
  return (
    <Fragment>
      <LoadingView />
      <div className={styles.container}>
        {showToolbar && (
          <div style={{ width: '100%' }}>
            <AppNavBar
              showBackButton={showBackButton}
              showLogoutButton={showLogoutButton}
              showRegisterMode={showRegisterMode}
              showRecoverMode={showRecoverMode}
              showOrderMode={showOrderMode}
              showProfileMode={showProfileMode}
            />
          </div>
        )}
        {children}
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  const {
    showToolbar,
    showBackButton,
    showLogoutButton,
    showRegisterMode,
    showRecoverMode,
    showOrderMode,
    showProfileMode,
  } = state.app;
  return {
    showToolbar,
    showBackButton,
    showLogoutButton,
    showRegisterMode,
    showRecoverMode,
    showOrderMode,
    showProfileMode,
    fetchUserOrders,
    fetchUserVisits,
    fetchUserAddresses,
    fetchProducts,
    fetchCategories,
    fetchUserDetails,
  };
};

export default connect(mapStateToProps)(AppPage);
