import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-free-solid';

const installOnIos = 'installOnIos';

class InstallOnIos extends React.Component {
  navImageUrl = require('../../assets/logo_top.png');
  directIcon = require('../../assets/action.png');

  state = {
    isCloseModal: true,
  };
  componentDidMount() {
    if (this.needsToSeePrompt()) {
      this.setState({
        isCloseModal: false,
      });
    }
  }

  render() {
    return !this.state.isCloseModal && !sessionStorage[installOnIos] ? (
      <div className="row" style={style.container}>
        <div className="col-12">
          <div className="row" style={style.title}>
            <div className="col-12 offset-5">
              <FontAwesomeIcon
                height="32px"
                width="32px"
                icon={faTimes}
                style={style.icon}
                onClick={() => this.closeModal()}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img src={this.navImageUrl} alt="Header" />
            </div>
          </div>
          <div className="row" style={style.description}>
            <div className="col-12">
              <span>
                Instalar la aplicación es muy fácil solo tienes que pulsar el
                icono{' '}
                <img src={this.directIcon} alt="Header" style={style.img} /> y
                'Añadir a pantalla de inicio'
              </span>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }

  closeModal() {
    this.setState({
      isCloseModal: true,
    });
    sessionStorage[installOnIos] = true;
  }

  needsToSeePrompt() {
    if (navigator.standalone) {
      return false;
    }
    return ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
  }
}
export default InstallOnIos;

const style = {
  container: {
    background: '#EFEFEF',
    opacity: '0.97',
    position: 'fixed',
    zIndex: '999',
    bottom: '0',
    marginRight: '0',
    marginLeft: '0',
  },
  img: {
    marginBottom: '5px',
  },
  icon: {
    paddinRight: '5px',
    marginTop: '5px',
  },
  description: {
    marginBottom: '15px',
    marginTop: '15px',
  },
  title: {
    marginTop: '10px',
  },
};
