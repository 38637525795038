import React from 'react';
import styles from './SegmentedControl.module.css';
import SegmentedControlItem from './SegmentedControlItem';

const SegmentedControl = ({
  selectedIndex,
  categories,
  handleItemSelected,
}) => {
  return (
    <div className={styles.container}>
      {categories &&
        categories.length > 0 &&
        categories.map((category, index) => (
          <SegmentedControlItem
            key={index}
            selected={selectedIndex == index}
            id={index}
            title={category.name}
            onSelect={handleItemSelected}
          />
        ))}
    </div>
  );
};

export default SegmentedControl;
