import React from 'react';
import style from './InvoiceItem.module.css';
import QuantityCounter from '../../products/QuantityCounter/QuantityCounter';
import { translateLiteral } from '../../../core/literals/Literal';

const InvoiceItem = ({
  productId,
  title,
  price,
  quantity,
  valueChanged,
  packItems,
  products,
}) => {
  return (
    <div className={`${style.container}`}>
      <div className={style.title}>{title}</div>
      {packDetail(packItems, products, quantity)}
      <div className={style.dataContainer}>
        <div className={style.price}>{price} €</div>
        <div className={style.amount}>
          {(price * quantity).toFixed(2)} €
        </div>
        <QuantityCounter productId={productId} valueChanged={valueChanged} />
      </div>
    </div>
  );
};

function packDetail(packItems, products = [], numberOfPacks) {
  return packItems && packItems.length ? (
    <div className={style.packItems}>
      {packItems.map(packItem => {
        const product = products.find(
          product => product.id === packItem.product_name
        );
        return (
          product && (
            <div className={style.packItemLine}>
              <div className={style.packIteTitle}>{product.name}</div>
              <div className={style.packItemAmount}>
                {numberOfPacks * packItem.product_quantity}{' '}
                {translateLiteral('products.units')}
              </div>
            </div>
          )
        );
      })}
    </div>
  ) : null;
}

export default InvoiceItem;
