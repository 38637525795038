import React from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../assets/fonts/icons/selection.json';

const AppIcon = props => {
  const { color, size, icon, onClick = () => {} } = props;
  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color ? color : ''}
      size={size}
      icon={icon}
      onClick={evt => onClick}
    />
  );
};

AppIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AppIcon.defaultProps = {};

export default AppIcon;
