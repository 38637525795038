import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import OrderListCell from './OrderListCell/OrderListCell';
import { connect } from 'react-redux';
import * as safeAreaInsets from 'safe-area-insets';
import LoadingView from '../../core/ui/LoadingView/LoadingView';

const OrdersList = props => {
  const inProgressOrder = props.items.find(order => order.state == 'draft');
  return (
    <>
      <LoadingView />
      {props.items && props.items.length ? (
        <List
          style={{
            paddingBottom: `${safeAreaInsets.bottom + 138}px`,
            marginTop: '0px',
            paddingTop: '8px',
            backgroundColor: '#F9F9F9',
          }}
        >
          {props.items.map((order, index) => {
            return (
              <ListItem button key={index}>
                <OrderListCell
                  order={order}
                  address={addressForOrder(order.partner_shipping_id, props)}
                  canRepeat={!inProgressOrder}
                />
              </ListItem>
            );
          })}
        </List>
      ) : null}
    </>
  );
};

function addressForOrder(addressId, props) {
  const addresses =
    props.addresses &&
    props.addresses.filter(address => address.id == addressId);
  return addresses && addresses.length ? addresses[0] : null;
}

const mapStateToProps = state => {
  return {
    addresses: state.user.addresses,
    items: state.orders.items,
  };
};

export default connect(mapStateToProps)(OrdersList);
