import {
  FETCH_USER_VISITS,
  FETCHING_USER_VISITS_SUCCESS,
  FETCHING_USER_VISITS_FAIL,
  FETCH_USER_DETAILS,
  FETCHING_USER_DETAILS_SUCCESS,
  FETCHING_USER_DETAILS_FAIL,
  FETCH_USER_ADDRESSES,
  FETCHING_USER_ADDRESSES_SUCCESS,
  FETCHING_USER_ADDRESSES_FAIL,
  FETCH_USER_CHANGE_PASSWORD,
  FETCHING_USER_CHANGE_PASSWORD_SUCCESS,
  FETCHING_USER_CHANGE_PASSWORD_FAIL,
  FETCH_USER_RESET_PASSWORD,
  FETCHING_USER_RESET_PASSWORD_SUCCESS,
  FETCHING_USER_RESET_PASSWORD_FAIL,
} from './types';

import {
  requestUserVisits,
  requestUserData,
  requestUserAddresses,
  requestUserChangePassword,
  requestUserResetPassword,
} from '../../../api/userRequests';

import { fetchAPI } from '.';

const fetchUserVisits = () =>
  fetchAPI(
    requestUserVisits,
    FETCH_USER_VISITS,
    FETCHING_USER_VISITS_SUCCESS,
    FETCHING_USER_VISITS_FAIL,
    null,
    'visits'
  );

const fetchUserDetails = () =>
  fetchAPI(
    requestUserData,
    FETCH_USER_DETAILS,
    FETCHING_USER_DETAILS_SUCCESS,
    FETCHING_USER_DETAILS_FAIL
  );

const fetchChangePassword = (oldPassword, newPassword) =>
  fetchAPI(
    requestUserChangePassword,
    FETCH_USER_CHANGE_PASSWORD,
    FETCHING_USER_CHANGE_PASSWORD_SUCCESS,
    FETCHING_USER_CHANGE_PASSWORD_FAIL,
    { oldPassword, newPassword }
  );

const fetchResetPassword = () =>
  fetchAPI(
    requestUserResetPassword,
    FETCH_USER_RESET_PASSWORD,
    FETCHING_USER_RESET_PASSWORD_SUCCESS,
    FETCHING_USER_RESET_PASSWORD_FAIL
  );

const fetchUserAddresses = () =>
  fetchAPI(
    requestUserAddresses,
    FETCH_USER_ADDRESSES,
    FETCHING_USER_ADDRESSES_SUCCESS,
    FETCHING_USER_ADDRESSES_FAIL
  );

export {
  fetchUserVisits,
  fetchUserDetails,
  fetchUserAddresses,
  fetchChangePassword,
  fetchResetPassword,
};
