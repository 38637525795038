import { getToken, invalidateSession } from './authManager';

export const host = process.env.REACT_APP_API_HOST;

export function fetchWithTimeout(uri, data) {
    const FETCH_TIMEOUT = 50000;
    let didTimeOut = false;

    console.log(uri)
    const url = new URL(uri);


    if (data && data.queryParams) {
        url.search = new URLSearchParams(data.queryParams);
    }
    return new Promise(function(resolve, reject) {
        const timeout = setTimeout(function() {
            didTimeOut = true;
            reject(new Error('Request timed out'));
        }, FETCH_TIMEOUT);

        const headers = {
            ...(data && data.headers),
            Authorization: getToken(),
            'Content-Type': 'application/json',
        };
        const params = {...data, headers };
        return fetch(url, params)
            .then(function(response) {
                // Clear the timeout as cleanup
                clearTimeout(timeout);
                if (!didTimeOut) {
                    if (response.status == 403) {
                        invalidateSession();
                        reject({ errorMessage: 'Not Authorized' });
                    }
                    resolve(response.json());
                }
            })
            .catch(function(err) {
                if (didTimeOut) return;
                reject(err);
            });
    });
}