import React, { useState } from 'react';
import styles from './OrderListCell.module.css';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OrderListCellInfo from './OrderListCellInfo';
import { translateLiteral } from '../../../core/literals/Literal';
import moment from 'moment';

const OrderListCell = ({ order, address, canRepeat }) => {
  const [visibleInfo, setVisibleInfo] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.cellContainer}>
        <div className={styles.firstContainer}>
          <div
            className={`${styles.line} ${
              order.state == 'cancel' ? styles.cancelled : ''
            }`}
          />
          <div className={styles.dataContainer}>
            <div className={styles.title}>
              {translateLiteral('orders.order')}: {order.name}
            </div>
            <div className={styles.subtitle}>
              {moment(order.date_order, 'MM/DD/YYYY, HH:mm:ss').format(
                'DD/MM/YYYY'
              )}
            </div>
          </div>
        </div>
        <div className={styles.priceContainer}>
          <div className={styles.priceLabel}>{order.amount_total} €</div>
        </div>
        <div
          className={styles.expandButton}
          onClick={() => setVisibleInfo(!visibleInfo)}
        >
          {false ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      {visibleInfo && (
        <div className={styles.expandContainer}>
          <OrderListCellInfo
            order={order}
            address={address}
            canRepeat={canRepeat}
          />
        </div>
      )}
    </div>
  );
};

export default OrderListCell;
