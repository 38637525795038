import { APP_TOOLBAR_HIDE, APP_TOOLBAR_SHOW, APP_TOOLBAR_SHOW_ORDER_WITH_BACK, APP_TOOLBAR_SHOW_RECOVER_WITH_BACK, APP_TOOLBAR_SHOW_WITH_BACK, APP_TOOLBAR_SHOW_WITH_LOGOUT, APP_TOOLBAR_SHOW_WITH_REGISTER,APP_TOOLBAR_SHOW_PROFILE_WITH_BACK } from '../actions/types';

export default (
  state = {
    showToolbar: false,
    showBackButton: false,
    showLogoutButton: false,
    showRegisterMode: false,
    showRecoverMode: false,
    showOrderMode: false,
    showProfileMode: false,
  },
  action
) => {
  switch (action.type) {
    case APP_TOOLBAR_SHOW_WITH_BACK:
      return {
        ...state,
        showToolbar: true,
        showBackButton: true,
        showLogoutButton: false,
        showRegisterMode: false,
      };

    case APP_TOOLBAR_SHOW_RECOVER_WITH_BACK:
      return {
        ...state,
        showToolbar: true,
        showBackButton: true,
        showLogoutButton: false,
        showRegisterMode: false,
        showRecoverMode: true,
      };

    case APP_TOOLBAR_SHOW_PROFILE_WITH_BACK:
      return {
        ...state,
        showToolbar: true,
        showBackButton: true,
        showLogoutButton: false,
        showRegisterMode: false,
        showRecoverMode: false,
        showOrderMode: false,
        showProfileMode: true
      };


    case APP_TOOLBAR_SHOW_ORDER_WITH_BACK:
      return {
        ...state,
        showToolbar: true,
        showBackButton: true,
        showLogoutButton: false,
        showRegisterMode: false,
        showRecoverMode: false,
        showOrderMode: true,
      };

    case APP_TOOLBAR_SHOW_WITH_REGISTER:
      return {
        ...state,
        showToolbar: true,
        showRegisterMode: true,
        showBackButton: true,
        showLogoutButton: false,
      };

    case APP_TOOLBAR_SHOW_WITH_LOGOUT:
      return {
        ...state,
        showToolbar: true,
        showBackButton: true,
        showLogoutButton: true,
        showRegisterMode: false,
        showProfileMode: true,
      };
    case APP_TOOLBAR_SHOW:
      return {
        ...state,
        showToolbar: true,
        showBackButton: false,
        showLogoutButton: false,
        showRegisterMode: false,
        showRecoverMode: false,
        showOrderMode: false,
        showProfileMode: false
      };

    case APP_TOOLBAR_HIDE:
      return {
        ...state,
        showToolbar: false,
        showLogoutButton: false,
        showBackButton: false,
        showRegisterMode: false,
        showRecoverMode: false,
      };

    default:
      return state;
  }
};
